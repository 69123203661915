import React, { useEffect } from 'react';
import './ModalContainer.scss';

const ModalContainer = ({ isShow, children }) => {

  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'scroll'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isShow])

  if (!isShow) {
    return <></>
  }

  return (
    <div className='modal-alert'>
      <div className='modal-alert__overlay'>
        {children}
      </div>
    </div>
  );
};

export default ModalContainer;