import React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';

import './Popup.scss';

const Popup = ({ number, handleClose }) => {
  return (
    <div className='popup popup-shown'>
      <div className='popup__text-block'>
        <span>the nail: </span>
        <span>{number}</span>
        <span>added to order!</span>
      </div>
      <CloseIcon onClick={handleClose} />
    </div>
  )
}

export default Popup;