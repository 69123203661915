import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './styles/common/App.scss';
// import 'react-spring-bottom-sheet/dist/style.css';

import ScrollToTop from './hoc/ScrollToTop';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import Intro from './pages/Intro/Intro';
import Home from './pages/Home/Home';
import GoldenCristiano from './pages/GoldenCristiano/GoldenCristiano';
import Order from './pages/Order/Order';
import Contacts from './pages/Contacts/Contacts';
import Statistic from './pages/Statistics/Statistic';
import Participants from './pages/Participants/Participants';

function App() {
  return (
    <div className="app">
      <ScrollToTop>
        <Routes>
          <Route path={'/'} element={<Intro />} />
          <Route path={'/home'} element={<Home />} />
          <Route path={'/CR7'} element={<GoldenCristiano />} />
          <Route path={'/order'} element={<Order />} />
          <Route path={'/contacts'} element={<Contacts />} />
          <Route path={'/statistic'} element={<Statistic />} />
          <Route path={'/participants'} element={<Participants />} />
          <Route path={'*'} element={<NotFoundPage />} />
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;
