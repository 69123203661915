import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { getCode } from 'country-list';
import { useSelector } from 'react-redux';

import './StatisticTable.scss';

const StatisticTable = ({ data }) => {
  const countries = useSelector((state) => state.countriesData.countries)

  const addZeroBeforeNumber = (number) => {
    const numberString = number.toString();
    return numberString.padStart(2, '0');
  }

  const getCountryCode = (countryName) => {
    return countries.find(item => item.country == countryName).iso2;
  }

  return (
    <div className='statistic-table'>
      {
        data.map((item, index) => {
          return (
            <div key={index} className='statistic-table__item'>
              <div>
                <span className='statistic-table__id-text'>{addZeroBeforeNumber(index + 1)}</span>
                <span className='statistic-table__country'>{item.country}</span>
                <ReactCountryFlag countryCode={getCode(item.country) ? getCode(item.country) : getCountryCode(item.country)} svg alt={item.country} />
              </div>
              
              <div>
                <span className='statistic-table__total-purchaces'>{item.total_purchaces}</span>
                <span className='statistic-table__nails'>nails</span>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default StatisticTable;