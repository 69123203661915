import React, { useEffect, useState } from 'react';

import './GoldenCristianoAboutNail.scss';

import API from '../../../../API/API';
import DomPurify from '../../../../components/sanitizeHtml/DomPurify';

import aboutNailsMainImage from '../../../../assets/golden-cristiano/about-nails-main-image.png';
import aboutNailsDetailImage from '../../../../assets/golden-cristiano/about-nails-detail-image.png';
import AboutNailLine from './AboutNailLine/AboutNailLine';
import WavyText from '../../../../components/WavyText/WavyText';
import { motion } from 'framer-motion';
import useMotion from '../../../../hooks/useMotion';
import bottomVariant from '../../../../animations/bottomVariant';

const GoldenCristianoAboutNail = () => {
  const { controls, ref } = useMotion();
  const [info, setInfo] = useState({
    title: 'The golden nails',
    description:
      'The nails are made of unique 750-gold (18-carat). Gold nails do not darken in contact with water and air, well tolerate abnormal temperature fluctuations even in space, and are resistant to friction.',
    text3: 'The weight of a single gold nail is 7 grams and its length is 2 centimeters.',
    image: aboutNailsMainImage,
    sub_text: 'The total weight of the gold nails used for the CR-7 is 5,444 grams.',
    image2: aboutNailsDetailImage
  });

  useEffect(() => {
    API.nailMap
      .mainPageInfo()
      .then((res) => {
        setInfo(res.data.about_nail);
      })
      .catch((err) => {
        console.error('error getting about nail data', err);
      });
  }, []);

  return (
    <div ref={ref} className="about-nail">
      <p className="about-nail__title">So, how we did it?</p>

      <div className="about-nail__content">
        <WavyText text={info?.title} textClassName={'text-center h3'} duration={0.3} />
        <motion.div
          animate={controls}
          initial="hidden"
          variants={bottomVariant}
          className="about-nail__left-block">
          <img className="about-nail__main-image" src={info?.image} alt="nails" />
        </motion.div>

        <motion.div
          animate={controls}
          initial="hidden"
          variants={bottomVariant}
          className="about-nail__right-block">
          <img
            className="about-nail__image about-nail__image_mobile"
            src={info?.image2}
            alt="nails"
          />
          <div className={'text text_sm about-nail__text'}>
            <DomPurify dirtyText={info?.description} />
          </div>
          <div className="about-nail__detail">
            <div className={'text text_sm'}>
              <DomPurify dirtyText={info?.sub_text} />
            </div>
            <img
              className="about-nail__image about-nail__image_tablet"
              src={info?.image2}
              alt="nails"
            />
            <div className="about-nail__description-block">
              <DomPurify className={'text text_sm about_nail__sub_text'} dirtyText={info?.text3} />
            </div>
          </div>
          <div className="about-nail__overflow"></div>
        </motion.div>
      </div>

      <AboutNailLine />
    </div>
  );
};

export default GoldenCristianoAboutNail;
