import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserTypeForStepOne } from '../../../store/cr7/orderFaceNailSlice';

import './OrderFirstStep.scss';

import ToggleBar from './Components/ToggleBar/ToggleBar';
import Personal from './Components/Personal/Personal';
import Company from './Components/Company/Company';
import { useFormContext } from "react-hook-form";
import ReactHookFormInput from "../../../components/Inputs/Input/ReactHookFormInput";

const OrderFirstStep = ({isBackgroundNails, setModalText, setShowAlert}) => {
  const inputState = useSelector(state => state.orderFaceNail)
  const isCompany = useSelector(state => state.orderFaceNail.company);
  const dispatch = useDispatch();
  const { register, formState: {errors}, setValue } = useFormContext();

  function resetValues() {
    setValue('name', '')
    setValue('email', '')
    setValue('phone_number', '')
  }

  const handleTogglePerson = () => {
    if (isBackgroundNails) {
      setModalText('Nails in the background area you can buy only through the form for "Company"');
      setShowAlert(true);
      return;
    }

    dispatch(setUserTypeForStepOne({company: false}))
    resetValues()
  }

  const handleToggleCompany = () => {
    if (!isBackgroundNails) {
      setModalText('Nails in the face area you can buy only through the form for "Person".');
      setShowAlert(true);
      return;
    }

    dispatch(setUserTypeForStepOne({company: true}))
    resetValues()
  }

  useEffect(() => {
    dispatch(setUserTypeForStepOne({company: isBackgroundNails}))
  }, [isBackgroundNails])

  return (
    <div className='second-step'>
      <div className='second-step__content'>
        <div className='second-step_header'>
          <h2 className='text-gold'>step 1.</h2>
          <h4>your personal info</h4>
        </div>

        <div className='second-step__form'>
          <span className='second-step__text-info'>ORDER AS</span>
          <ToggleBar
            isCompany={isCompany}
            handleTogglePerson={handleTogglePerson}
            handleToggleCompany={handleToggleCompany} />
          {
            isCompany
              ? <Company />
              : <Personal />
          }
          <ReactHookFormInput
            label='your website'
            value={inputState.company_link}
            name='company_link'
            placeholder={'my-website-address.com'}
            maxLength={200}
            {...register("company_link")}
            errorMsg={errors?.company_link?.message}
          />
          <ReactHookFormInput
            label='instagram link'
            value={inputState.instagram}
            name='instagram'
            placeholder={'@my-account link'}
            maxLength={200}
            {...register("instagram")}
            errorMsg={errors?.instagram?.message}
          />
          <ReactHookFormInput
            label='facebook link'
            value={inputState.facebook}
            name='facebook'
            placeholder={'my-facebook link'}
            maxLength={200}
            {...register("facebook")}
            errorMsg={errors?.facebook?.message}
          />
          <ReactHookFormInput
            label='twitter link'
            value={inputState.twitter}
            name='twitter'
            placeholder={'my-twitter-profile link'}
            maxLength={200}
            {...register("twitter")}
            errorMsg={errors?.twitter?.message}
          />
        </div>
      </div>
    </div>
  )
}

export default OrderFirstStep;