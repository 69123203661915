/**
 * Size 'lg' - for General partners
 * Size 'sm' - for Common partners
 *
 * @param {string} title
 * @param {'sm' | 'lg'} size
 * @param {Partner[]} partners
 */

import React from 'react';
import './Partners.scss';
import WavyText from "../WavyText/WavyText";

const Partners = ({ title = '', size = 'lg', partners = [], className = '' }) => {
  return (
    <div className={`partners ${className}`}>
      <WavyText containerClassName={`title_margin_${size}`} isLetterSplit={window.innerWidth > 768} textClassName={'title'} text={title} />

      <div className={`content content_${size} ${partners.length == 1 && 'single-partner'} ${partners.length == 2 && 'double-partner'}`}>
        {partners.map((item) => {
          if (!item?.company_link) {
            return <img className={`logo__image logo__image_${size}`} src={item.logo} key={item.id} alt={item.company}/>
          }

          return (
            <a className={'logo'} href={item.company_link} key={item.id} target={'_blank'} rel="noreferrer">
              <img className={`logo__image logo__image_${size}`} src={item.logo} alt={item.company}/>
            </a>
          )
        })}
      </div>
    </div>
  );
};

export default Partners;