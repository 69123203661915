import React, { useEffect, useState } from 'react';

import './Intro.scss';

import { ReactComponent as Signature } from '../../assets/signature.svg';
import { ReactComponent as FullLogo } from '../../assets/full-logo.svg';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import useMotion from '../../hooks/useMotion';
import imageOpacityVariant from '../../animations/imageOpacityVariant';
import bottomVariant from '../../animations/bottomVariant';

function renderIntroFirstStep(controls) {
  return (
    <>
      <FullLogo />
      <motion.div
        animate={controls}
        initial="hidden"
        variants={imageOpacityVariant}
        className="flashlight flashlight-first-step">
        <span />
        <motion.p animate={controls} initial="hidden" variants={bottomVariant}>
          Once in a century, legends emerge that unite millions of people around them.
        </motion.p>
      </motion.div>
    </>
  );
}

function renderIntroSecondStep(controls) {
  return (
    <>
      <FullLogo />
      <motion.div
        animate={controls}
        initial="hidden"
        variants={imageOpacityVariant}
        className="flashlight flashlight-second-step">
        <motion.span animate={controls} initial="hidden" variants={bottomVariant}>
          It is these personalities who write golden pages in the glorious history of mankind,
          inspiring excellence, promoting goodness, healthy living and tolerance to the world.
        </motion.span>
        <motion.div
          animate={controls}
          initial="hidden"
          variants={bottomVariant}
          className="flashlight__item">
          <Signature />
          <span>A. DZHANALIEV</span>
        </motion.div>
      </motion.div>
    </>
  );
}

const Intro = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const { controls, ref } = useMotion();
  const { controls: controls2, ref: ref2 } = useMotion();

  useEffect(() => {
    const t1 = setTimeout(() => {
      setCurrentStep(1);
    }, 2000);

    const t2 = setTimeout(() => {
      navigate('home');
    }, 3000);

    return () => {
      clearTimeout(t1);
      clearTimeout(t2);
    };
  }, []);

  return (
    <>
      {currentStep === 0 ? (
        <div ref={ref} className="intro">
          {renderIntroFirstStep(controls)}
        </div>
      ) : (
        <div ref={ref2} className="intro">
          {renderIntroSecondStep(controls2)}
        </div>
      )}
    </>
  );
};

export default Intro;
