import React from 'react';
import './Divider.scss';
import Star from '../../assets/star.png';

const Divider = ({ withMargin = false, className = '' }) => {
  // const element = useRef(null);

  // useEffect(() => {
  //   const scrollFun = () => {
  //     if (element?.current) {
  //       if (window.innerHeight + window.pageYOffset > element.current.getBoundingClientRect().bottom + 100) {
  //         element.current.classList.add('divider_active')
  //       } else {
  //         element.current.classList.remove('divider_active')
  //       }
  //     }
  //   }
  //
  //   window.addEventListener("scroll", scrollFun);
  //
  //   return () => {
  //     window.removeEventListener("scroll", scrollFun);
  //   };
  // });

  return (
    <div
      // ref={element}
      className={`divider ${withMargin ? 'divider_margin' : ''} ${className}`}>
      <img colorInterpolationFilters="sRGB" src={Star} alt="star" className={'star'} />
    </div>
  );
};

export default Divider;
