import React from 'react';
import { motion } from 'framer-motion';
import './WavyText.scss';
import useMotion from '../../hooks/useMotion';

const WavyText = ({
  containerClassName = '',
  textClassName = '',
  text = '',
  isLetterSplit = false,
  duration = 0.05,
  childrenDelay = 0.03
}) => {
  const { controls, ref } = useMotion();

  const wavyContainer = {
    hidden: {
      opacity: 0
    },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: duration, delayChildren: i * childrenDelay }
    })
  };

  const wavyText = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        // type: "spring",
        // damping: 12,
        // stiffness: 200
      }
    },
    hidden: {
      opacity: 0,
      y: 20,
      transition: {
        // type: "spring",
        // damping: 12,
        // stiffness: 200
      }
    }
  };

  return (
    <motion.div
      className={`wavy-text ${containerClassName}`}
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={wavyContainer}>
      {isLetterSplit ? (
        <>
          {text?.split('').map((letter, index) => (
            <motion.span className={textClassName} key={index} variants={wavyText}>
              {letter === ' ' ? '\u00A0' : letter}
            </motion.span>
          ))}
        </>
      ) : (
        <>
          {text?.split(' ').map((word, index) => (
            <motion.span className={textClassName} key={index} variants={wavyText}>
              {word}&nbsp;
            </motion.span>
          ))}
        </>
      )}
    </motion.div>
  );
};

export default WavyText;
