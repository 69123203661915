import { configureStore } from '@reduxjs/toolkit';
import nailsReducer from './cr7/nailsSlice';
import countriesReducer from './cr7/coutriesSlice';
import orderFaceNailReducer from './cr7/orderFaceNailSlice';

export const store = configureStore({
  reducer: {
    nails: nailsReducer,
    orderFaceNail: orderFaceNailReducer,
    countriesData: countriesReducer
  },
})