import React, { useState, useEffect } from 'react';
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import { getCode } from 'country-list';
import { useSelector } from 'react-redux';
import API from '../../../../API/API';
import WavyText from '../../../../components/WavyText/WavyText';

import './GoldenCristianoParticipants.scss';

import Button from "../../../../components/Button/Button";
import Divider from "../../../../components/Divider/Divider";

const GoldenCristianoParticipants = () => {
  const navigate = useNavigate();
  const [participants, setParticipants] = useState([]);
  const countries = useSelector((state) => state.countriesData.countries);

  const getCountryCode = (countryName) => {
    return countries.find(item => item.country == countryName).iso2;
  }

  useEffect(() => {
    API.participants.getParticipants(1, 7)
      .then(res => {
        setParticipants(res.data.results);
      })
      .catch(err => {
        console.log("err", err)
      })
  }, [])

  const showParticipants = () => {
    navigate('/participants');
  }

  return (
    <div className='participants members'>
      <WavyText text={'Guardians of Heritage'} textClassName={'text-center h3'} isLetterSplit={window.innerWidth > 768}/>
    
      <table>
        <tbody>
        {participants && participants.length ? (
          participants.map(item => (
            // TODO add normal key from id
            <tr key={item.name + item.email + Math.random()}>
              <td className={'td-nail'}>
                {/* TODO add user nail (currently no data in backend)*/}
                <p className={'text text_gold'}>{item.nails.map((item) => item.number).join(', ')}</p>
                <p className={'text text_gold text_mobile'}>{item.name}</p>
              </td>
              <td className={'text td-name'}>{item.name}</td>
              <td className={'text td-country'}>
                {item.country} <ReactCountryFlag style={{ width: '27px', height: '20px' }} className={'flag'}
                                                  countryCode={getCode(item.country) ? getCode(item.country) : getCountryCode(item.country)} svg alt={item.country} />
              </td>
              {
                item.twitter || item.instagram || item.facebook && 
                  <td className={'td-info'}>
                    {/* <p className={'text text_gold'}>{item.email}</p> */}
    
                    {/* TODO replace twitter to user site */}
                    {item.twitter ? (
                      <>
                        <div className={'participants__dot'}/>
                        <p className={'text text_gold'}>my_site.com</p>
                      </>
                    ) : null}
    
                    {
                      item?.instagram || item.facebook ? (
                        <div className={'social'}>
                          {item?.instagram ? (
                            <>
                              <div className={'participants__dot'}/>
                              <a className={'text text_gold'} target={'_blank'} rel={'noreferrer'}
                                  href={item.instagram || '#'} aria-label={item.instagram}>in</a>
                            </>
                          ) : null}
    
    
                          {item?.facebook ? (
                            <>
                              <div className={'participants__dot participants__dot_mobile'}/>
                              <a className={'text text_gold'} target={'_blank'} rel={'noreferrer'}
                                  href={item.facebook || '#'} aria-label={item.facebook}>fb</a>
                            </>
                          ) : null}
                        </div>
                      ) : null
                    }
                  </td>
              }
            </tr>
          ))
        ) : null}
        </tbody>
      </table>

      <Button type={'primary'} className={'button member-button'} title={'View all'} onClick={showParticipants} />
      <Divider withMargin />
    </div>
  )
}

export default GoldenCristianoParticipants;