import React from 'react';
import './AboutNailLine.scss';
import useDrawLine from '../../../../../hooks/useDrawLine';
import { motion } from 'framer-motion';
import starVariant from '../../../../../animations/lineStarVariant';

const AboutNailLine = () => {
  const { line, container, drawPercent } = useDrawLine(500);

  return (
    <div ref={container} className={'about-nail-line'}>
      <svg
        width="1206"
        height="3982"
        viewBox="0 0 1206 3982"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          ref={line}
          d="M604 0V87.5L1115.5 459L25.5 1053.5L71.5 874L1142.5 1989.5L1179 1814L204 2327L1194 2968.5L1142.5 2760.5L165.5 3321L12 3187L165.5 3117L603 3871V3981.5"
          stroke="#D5C493"
        />

        {/* SECOND */}
        {drawPercent > 24.25 ? (
          <motion.g
            variants={starVariant}
            initial="hidden"
            animate="visible"
            filter="url(#filter0_d_1802_527)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M68.2427 871.243C70.5859 873.586 74.3848 873.586 76.728 871.243C74.3848 873.586 74.3848 877.385 76.728 879.728C74.3848 877.385 70.5859 877.385 68.2427 879.728C70.5859 877.385 70.5859 873.586 68.2427 871.243Z"
              fill="#FFFFD3"
            />
          </motion.g>
        ) : null}

        {/* FIRST */}
        {drawPercent > 22.16 ? (
          <motion.g
            variants={starVariant}
            initial="hidden"
            animate="visible"
            filter="url(#filter1_d_1802_527)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.2427 1049.24C23.5859 1051.59 27.3848 1051.59 29.728 1049.24C27.3848 1051.59 27.3848 1055.38 29.728 1057.73C27.3848 1055.38 23.5859 1055.38 21.2427 1057.73C23.5859 1055.38 23.5859 1051.59 21.2427 1049.24Z"
              fill="#FFFFD3"
            />
          </motion.g>
        ) : null}

        {/* THIRD */}
        {drawPercent > 25.85 ? (
          <motion.g
            variants={starVariant}
            initial="hidden"
            animate="visible"
            filter="url(#filter2_d_1802_527)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M164.243 970.243C166.586 972.586 170.385 972.586 172.728 970.243C170.385 972.586 170.385 976.385 172.728 978.728C170.385 976.385 166.586 976.385 164.243 978.728C166.586 976.385 166.586 972.586 164.243 970.243Z"
              fill="#FFFFD3"
            />
          </motion.g>
        ) : null}

        {/* FIFTH */}
        {drawPercent > 45.47 ? (
          <motion.g
            variants={starVariant}
            initial="hidden"
            animate="visible"
            filter="url(#filter3_d_1802_527)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1038.24 1881.24C1040.59 1883.59 1044.38 1883.59 1046.73 1881.24C1044.38 1883.59 1044.38 1887.38 1046.73 1889.73C1044.38 1887.38 1040.59 1887.38 1038.24 1889.73C1040.59 1887.38 1040.59 1883.59 1038.24 1881.24Z"
              fill="#FFFFD3"
            />
          </motion.g>
        ) : null}

        {/* EIGHTH */}
        {drawPercent > 71.93 ? (
          <motion.g
            variants={starVariant}
            initial="hidden"
            animate="visible"
            filter="url(#filter4_d_1802_527)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1138.24 2757.24C1140.59 2759.59 1144.38 2759.59 1146.73 2757.24C1144.38 2759.59 1144.38 2763.38 1146.73 2765.73C1144.38 2763.38 1140.59 2763.38 1138.24 2765.73C1140.59 2763.38 1140.59 2759.59 1138.24 2757.24Z"
              fill="#FFFFD3"
            />
          </motion.g>
        ) : null}

        {/* SIXTH */}
        {drawPercent > 66.94 ? (
          <motion.g
            variants={starVariant}
            initial="hidden"
            animate="visible"
            filter="url(#filter5_d_1802_527)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M995.243 2838.24C997.586 2840.59 1001.38 2840.59 1003.73 2838.24C1001.38 2840.59 1001.38 2844.38 1003.73 2846.73C1001.38 2844.38 997.586 2844.38 995.243 2846.73C997.586 2844.38 997.586 2840.59 995.243 2838.24Z"
              fill="#FFFFD3"
            />
          </motion.g>
        ) : null}

        {/* SEVENTH */}
        {drawPercent > 69.55 ? (
          <motion.g
            variants={starVariant}
            initial="hidden"
            animate="visible"
            filter="url(#filter6_d_1802_527)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1188.24 2963.24C1190.59 2965.59 1194.38 2965.59 1196.73 2963.24C1194.38 2965.59 1194.38 2969.38 1196.73 2971.73C1194.38 2969.38 1190.59 2969.38 1188.24 2971.73C1190.59 2969.38 1190.59 2965.59 1188.24 2963.24Z"
              fill="#FFFFD3"
            />
          </motion.g>
        ) : null}

        {/* ELEVENTH */}
        {drawPercent > 88.8 ? (
          <motion.g
            variants={starVariant}
            initial="hidden"
            animate="visible"
            filter="url(#filter7_d_1802_527)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M161.243 3113.24C163.586 3115.59 167.385 3115.59 169.728 3113.24C167.385 3115.59 167.385 3119.38 169.728 3121.73C167.385 3119.38 163.586 3119.38 161.243 3121.73C163.586 3119.38 163.586 3115.59 161.243 3113.24Z"
              fill="#FFFFD3"
            />
          </motion.g>
        ) : null}

        {/* TENTH */}
        {drawPercent > 86.9 ? (
          <motion.g
            variants={starVariant}
            initial="hidden"
            animate="visible"
            filter="url(#filter8_d_1802_527)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.24271 3182.24C11.5859 3184.59 15.3848 3184.59 17.728 3182.24C15.3848 3184.59 15.3848 3188.38 17.728 3190.73C15.3848 3188.38 11.5859 3188.38 9.24271 3190.73C11.5859 3188.38 11.5859 3184.59 9.24271 3182.24Z"
              fill="#FFFFD3"
            />
          </motion.g>
        ) : null}

        {/* NINE */}
        {drawPercent > 84.67 ? (
          <motion.g
            variants={starVariant}
            initial="hidden"
            animate="visible"
            filter="url(#filter9_d_1802_527)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M161.243 3316.24C163.586 3318.59 167.385 3318.59 169.728 3316.24C167.385 3318.59 167.385 3322.38 169.728 3324.73C167.385 3322.38 163.586 3322.38 161.243 3324.73C163.586 3322.38 163.586 3318.59 161.243 3316.24Z"
              fill="#FFFFD3"
            />
          </motion.g>
        ) : null}

        {/* TWELFTH */}
        {drawPercent > 98.7 ? (
          <motion.g
            variants={starVariant}
            initial="hidden"
            animate="visible"
            filter="url(#filter10_d_1802_527)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M599.243 3866.24C601.586 3868.59 605.385 3868.59 607.728 3866.24C605.385 3868.59 605.385 3872.38 607.728 3874.73C605.385 3872.38 601.586 3872.38 599.243 3874.73C601.586 3872.38 601.586 3868.59 599.243 3866.24Z"
              fill="#FFFFD3"
            />
          </motion.g>
        ) : null}

        {/* FOURTH */}
        {drawPercent > 43.75 ? (
          <motion.g
            variants={starVariant}
            initial="hidden"
            animate="visible"
            filter="url(#filter11_d_1802_527)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1174.24 1810.24C1176.59 1812.59 1180.38 1812.59 1182.73 1810.24C1180.38 1812.59 1180.38 1816.38 1182.73 1818.73C1180.38 1816.38 1176.59 1816.38 1174.24 1818.73C1176.59 1816.38 1176.59 1812.59 1174.24 1810.24Z"
              fill="#FFFFD3"
            />
          </motion.g>
        ) : null}

        <defs>
          <filter
            id="filter0_d_1802_527"
            x="59.2427"
            y="862.243"
            width="26.4854"
            height="26.4854"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_527" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1802_527"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_1802_527"
            x="12.2427"
            y="1040.24"
            width="26.4854"
            height="26.4854"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_527" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1802_527"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_1802_527"
            x="155.243"
            y="961.243"
            width="26.4854"
            height="26.4854"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_527" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1802_527"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_1802_527"
            x="1029.24"
            y="1872.24"
            width="26.4854"
            height="26.4854"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_527" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1802_527"
              result="shape"
            />
          </filter>
          <filter
            id="filter4_d_1802_527"
            x="1129.24"
            y="2748.24"
            width="26.4854"
            height="26.4854"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_527" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1802_527"
              result="shape"
            />
          </filter>
          <filter
            id="filter5_d_1802_527"
            x="986.243"
            y="2829.24"
            width="26.4854"
            height="26.4854"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_527" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1802_527"
              result="shape"
            />
          </filter>
          <filter
            id="filter6_d_1802_527"
            x="1179.24"
            y="2954.24"
            width="26.4854"
            height="26.4854"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_527" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1802_527"
              result="shape"
            />
          </filter>
          <filter
            id="filter7_d_1802_527"
            x="152.243"
            y="3104.24"
            width="26.4854"
            height="26.4854"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_527" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1802_527"
              result="shape"
            />
          </filter>
          <filter
            id="filter8_d_1802_527"
            x="0.242676"
            y="3173.24"
            width="26.4854"
            height="26.4854"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_527" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1802_527"
              result="shape"
            />
          </filter>
          <filter
            id="filter9_d_1802_527"
            x="152.243"
            y="3307.24"
            width="26.4854"
            height="26.4854"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_527" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1802_527"
              result="shape"
            />
          </filter>
          <filter
            id="filter10_d_1802_527"
            x="590.243"
            y="3857.24"
            width="26.4854"
            height="26.4854"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_527" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1802_527"
              result="shape"
            />
          </filter>
          <filter
            id="filter11_d_1802_527"
            x="1165.24"
            y="1801.24"
            width="26.4854"
            height="26.4854"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_527" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1802_527"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default AboutNailLine;
