import React, { useState, useEffect } from 'react';
import API from '../../../../../../API/API';
import { getCode } from 'country-list';
import { useSelector } from 'react-redux';
import ReactCountryFlag from 'react-country-flag';

import './CountryChart.scss';

const CountryChart = () => {
  const countries = useSelector((state) => state.countriesData.countries);
  const [countryStats, setCountryStats] = useState([]);

  const calculatePercentage = (amount) => {
    return `${(amount / countryStats[0].total_purchaces) * 100}%`;
  };

  const getCountryCode = (countryName) => {
    return countries.find((item) => item.country == countryName).iso2;
  };

  useEffect(() => {
    API.nailMap
      .countryStats()
      .then((res) => {
        const data = res.data;
        const heigherData = data.sort((a, b) => (a.total_purchaces < b.total_purchaces ? 1 : -1));
        setCountryStats(heigherData.slice(0, 7));
      })
      .catch((error) => {
        console.error('error getting country statistics data', error);
      });
  }, []);

  return (
    <div className="country-chart">
      <span>country chart</span>

      <div className="country-chart__countries-block">
        {countryStats.map((item, index) => {
          return (
            <div key={index} className="country-chart__item">
              <div className="country-chart__info">
                <ReactCountryFlag
                  countryCode={
                    getCode(item.country) ? getCode(item.country) : getCountryCode(item.country)
                  }
                  svg
                  alt={item.country}
                />
                <span className="country-chart__country">{item.country}</span>
                <span className="country-chart__nail">{item.total_purchaces} nails</span>
              </div>
              <div className="country-chart__progressbar">
                <div
                  className="country-chart__progressbar-status"
                  style={{ width: calculatePercentage(item.total_purchaces) }}></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CountryChart;
