import React, { useCallback, useState, useEffect } from 'react';
import './HomeTheName.scss';
import Logo from '../../../../assets/ronaldo-logo.svg';
import Button from '../../../../components/Button/Button';
import Divider from '../../../../components/Divider/Divider';
import { useNavigate } from 'react-router-dom';
import API from '../../../../API/API';
import { motion } from 'framer-motion';
import useMotion from '../../../../hooks/useMotion';
import Goals from '../../../../assets/home/the-name-goals-mobile.svg';
import bottomVariant from '../../../../animations/bottomVariant';
import imageOpacityVariant from '../../../../animations/imageOpacityVariant';

const HomeTheName = () => {
  const { controls, ref } = useMotion();
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    title: 'The name',
    description:
      'Participants from different continents, countries, and nations get to leave their name on the CR-7 collection portrait.',
    image: null
  });

  useEffect(() => {
    API.home
      .theName()
      .then((res) => {
        setInfo(res.data[0]);
      })
      .catch((err) => {
        console.error('error getting theName data', err);
      });
  }, []);

  const handleClick = useCallback(() => {
    navigate('/CR7');
  }, [navigate]);

  return (
    <div ref={ref} className="home-the-name">
      <motion.h1
        animate={controls}
        initial="hidden"
        variants={bottomVariant}
        className="text-center title_margin">
        {info?.title}
      </motion.h1>

      <div className="content">
        <motion.div
          animate={controls}
          initial="hidden"
          variants={imageOpacityVariant}
          className="main-image__container">
          {info?.image && <img className="main-image" src={info?.image} alt="ronaldo" />}
        </motion.div>

        <div className="description">
          <div className={'description__image'}>
            <img src={Goals} alt="goals" />
          </div>
          <div className="description__content">
            <motion.img
              animate={controls}
              initial="hidden"
              variants={bottomVariant}
              className="description__content_mobile_centered"
              src={Logo}
              alt="logo"
            />
            <motion.p
              animate={controls}
              initial="hidden"
              variants={bottomVariant}
              className="description__text">
              {info?.description}
            </motion.p>

            <motion.div
              animate={controls}
              initial="hidden"
              variants={bottomVariant}
              className="button-container description__content_mobile_centered">
              <Button onClick={handleClick} type={'primary'} title={'Run Project'} />
            </motion.div>
          </div>
        </div>
      </div>

      <div className="divider-container">
        <Divider withMargin />
      </div>
    </div>
  );
};

export default HomeTheName;
