import React, { forwardRef } from 'react';
import './Input.scss';
import { useFormContext } from 'react-hook-form';

const ReactHookFormInput = forwardRef((props) => {
  const {
    label,
    marked = false,
    name,
    placeholder,
    errorMsg,
    onChange,
    minLength = 1,
    maxLength,
    required = false,
    warningMsg = ''
  } = props;
  const { register } = useFormContext();

  return (
    <div className="input">
      <div className="input__content">
        <label>
          {label}
          {marked && <span className="input__required">*</span>}
        </label>
        <input
          type="text"
          placeholder={placeholder}
          minLength={minLength}
          maxLength={maxLength}
          onChange={onChange}
          required={required}
          {...register(name)}
          autoComplete="off"
        />
      </div>

      {errorMsg ? <span className="input__error">{errorMsg}</span> : null}

      <span className="input__info">{warningMsg}</span>
    </div>
  );
});

ReactHookFormInput.displayName = 'ReactHookFormInput';
export default ReactHookFormInput;
