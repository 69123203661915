import React, { useEffect, useState } from 'react';
import './Footer.scss';
import { HashLink } from "react-router-hash-link";
import { useNavigate, useLocation } from "react-router-dom";

import { ReactComponent as RonaldoLogo } from '../../assets/ronaldo-logo.svg';
import { ReactComponent as RonaldoLogoBlack } from '../../assets/ronaldo-logo-black.svg';
import { ReactComponent as TwitterIcon } from '../../assets/twitter.svg';
import { ReactComponent as InstagramIcon } from '../../assets/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
import API from "../../API/API";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isHover, setIsHover] = useState(false);
  const [info, setInfo] = useState({
    "telegram": "@constellationgram",
    "email": "office@c-art.gallery",
    "phone_number": "+996 777 07 07 07",
    "address": "Bishkek. Aitmatov ave 17",
    "instagram": '',
    "twitter": '',
    "facebook": ''
  })

  const handleMouseEnter = () => {
    setIsHover(true);
  }

  const handleMouseLeave = () => {
    setIsHover(false);
  }

  const handleClick = () => {
    const currentPathname = location.pathname;
    if (currentPathname !== '/CR7') {
      navigate('/CR7')
    } else {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }

  useEffect(() => {
    API.common.contacts()
      .then(res => {
        setInfo(res.data);
      })
      .catch(err => {
        console.error('error getting footer data', err)
      })
  }, [])

  return (
    <>
      <div className='footer'>
        <HashLink smooth to={'/home#home-program'}
                  className='footer__item footer__item_big'>ABOUT</HashLink>
        <div className='footer__item footer__item-logo-container' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
          <div className={'footer__item-logo'}>
            {
              isHover ? <RonaldoLogoBlack /> : <RonaldoLogo />
            }
          </div>
        </div>
        <HashLink smooth to={'/home#home-partners'}
                  className='footer__item footer__item_big'>PARTNERS</HashLink>
        <div className='footer__item footer__item-list'>
          <a target={'_blank'} rel={'noreferrer'} href={info.twitter} aria-label={info.twitter}><TwitterIcon /></a>
          <a target={'_blank'} rel={'noreferrer'} href={info.instagram} aria-label={info.instagram}><InstagramIcon /></a>
          <a target={'_blank'} rel={'noreferrer'} href={info.facebook} aria-label={info.facebook}><FacebookIcon /></a>
        </div>
        <a href={`mailto:${info.email}`} className='footer__item' aria-label={info.email}>{info.email}</a>
        <a href={`https://wa.me/${info.phone_number}`} className='footer__item' aria-label={info.phone_number}>{info.phone_number}</a>
        <p className='footer__item'>{info.address}</p>
      </div>
      <div className='footer__copyright'>©2022 c-art.gallery</div>
    </>
  );
}

export default Footer;