import React from 'react';

import './Home.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import HomeMain from "./Components/HomeMain/HomeMain";
import HomeMission from "./Components/HomeMIssion/HomeMission";
import HomeProgram from "./Components/HomeProgram/HomeProgram";
import HomeProgramSteps from "./Components/HomeProgramSteps/HomeProgramSteps";
import HomeTheName from "./Components/HomeTheName/HomeTheName";
import HomeAuthor from "./Components/HomeAuthor/HomeAuthor";
import HomePartners from "./Components/HomePartners/HomePartners";
import HomeMediaPartners from './Components/HomeMediaPartners/HomeMediaPartners';
import useScrollDirection from "../../hooks/useScrollDirection";

const Home = () => {
  const { scrollY } = useScrollDirection();

  return (
    <>
      <Header isFullLogo={scrollY > 0}/>
      <div className='home'>
        <HomeMain scrollY={scrollY} />
        <HomeMission />
        <HomeProgram />
        <HomeProgramSteps />
        <HomeTheName />
        <HomeAuthor />
        <HomePartners />
        <HomeMediaPartners />
      </div>
      <Footer/>
    </>
  )
}

export default Home;
