import React from 'react';
import './SliderButtons.scss';
import ArrowPrev from "../../../assets/arrow-prev-icon.svg";
import ArrowNext from "../../../assets/arrow-next-icon.svg";

const SliderPrevButton = ({onClick, text = 'Prev'}) => {
  return (
    <button onClick={onClick} className='slider-button'>
      <img className='slider-arrow slider-arrow_left' src={ArrowPrev} alt="arrow-prev"/>
      {text}
    </button>
  );
};

const SliderNextButton = ({onClick, text = 'Next'}) => {
  return (
    <button onClick={onClick} className='slider-button'>
      {text}
      <img className='slider-arrow slider-arrow_right' src={ArrowNext} alt="arrow-prev"/>
    </button>
  );
};

export {SliderPrevButton, SliderNextButton};