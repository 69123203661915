import React, { useEffect, useState } from 'react';

import './GoldenCristianoMediaPartners.scss';

import Partners from "../../../../components/Partners/Partners";
import Divider from "../../../../components/Divider/Divider";
import API from "../../../../API/API";

const GoldenCristianoMediaPartners = () => {
  const [info, setInfo] = useState({mediaPartner: []});

  useEffect(() => {
    API.nailMap.partners()
      .then(res => {
        setInfo({
          mediaPartner: res.data.filter(item => item.media_partner),
        })
      })
      .catch(err => {
        console.error('error getting mission data', err)
      })
  }, [])

  if (Object.values(info).every(item => item.length === 0)) {
    return <></>
  }

  return (
    <div id={'golden-cristiano-media-partners'} className='golden-cristiano-media-partners'>
      <Partners size={'sm'} title={'Media Partners'} partners={info.mediaPartner} />
      <Divider withMargin />
    </div>
  );
}

export default GoldenCristianoMediaPartners;