import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import * as d3 from 'd3';
import API from '../../API/API';
import { displayWidth } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setSelected, clearOrderList } from '../../store/cr7/nailsSlice';

import './SpotPortrait.scss';

import backgroundImage from '../../assets/overlay.webp';

import ModalAlert from '../ModalAlert/ModalAlert';

const SpotPortrait = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const filtersState = useSelector((state) => state.nails.filters);
  const stateOfSelectedData = useSelector((state) => state.nails.selectedData);
  const orderList = useSelector((state) => state.nails.orderList);
  const removedOrderedNail = useSelector((state) => state.nails.removedOrderedNail);
  
  const margins = { top: 5, right: 5, bottom: 5, left: 5 };
  const screenWidth = window.innerWidth, screenHeihgt = window.innerHeight; 
  const resWidth = displayWidth.find((item) => item.width == screenWidth);
  const resHeight = displayWidth.find((item) => item.width == screenWidth);

  const [prevFocused, setPrevFocused] = useState(props.initialNail);
  const [focused, setFocused] = useState(props.initialNail);
  const [showAlert, setShowAlert] = useState(false);
  const [modalText, setModalText] = useState('');

  let boughtNails = props.numbers;
  let nails = props.nails;

  let width, height;

  if (resWidth) {
    if (props.isOrder) {
      if (screenWidth < 1024) {
        width = resWidth.portraitWidth + 40;
        height = resHeight.portraitHeight + 40;
      }
    } else {
      width = resWidth.portraitWidth;
      height = resHeight.portraitHeight;
    }
  } else {
    width = props.isOrder ? 360 : 372;
    height = props.isOrder ? 503 : 514;
  }

  const {svgWidth = '100%', svgHeight = screenWidth < 1024 ? '70vh' : '90vh', imageX = -2} = props;
  const innerWidth = width - margins.left - margins.right;
  const innerHeight = height - margins.top - margins.bottom;

  const xExtent = d3.extent(props.nails, (n) => n.x);
  const yExtent = d3.extent(nails, (n) => n.y);

  const getPurchasedNailData = (nail) => {
    const reg = /(?:[\w-]+\.)+(?:com|net|biz)/g;

    API.nailMap.buyersByNailNumber(nail.number)
      .then(res => {
        const data = res.data;
        const contacts = [];
        
        let selectedData = {
          isSelected: true,
          background: nail.background,
          number: nail.number,
          price: '',
          x: nail.x,
          y: nail.y,
          status: 'purchased'
        };

        if (Object.keys(data).length > 0) {
          if (data.instagram) contacts.push({label: 'instagram', value: data.instagram});
          if (data.facebook) contacts.push({label: 'facebook', value: data.facebook});
          if (data.twitter) contacts.push({label: 'twitter', value: data.twitter});
          if (data.company_link) contacts.push({label: data.company_link.match(reg)[0], value: data.company_link});
  
          selectedData.owner = data.company ? data.company : data.name;
          selectedData.country = `${data.country} / ${data.city}`;
          selectedData.contacts = contacts;
          selectedData.nails = data.nails;
        } 
        dispatch(setSelected(selectedData));
      })
      .catch(err => {
        console.error('error getting buyers by nail number data', err)
      })
  }

  const addZeroBeforeNumber = (number) => {
    const numberString = number.toString();
    return numberString.padStart(5, '0');
  }

  const handleModalAction = () => {
    dispatch(clearOrderList());
    setShowAlert(false);
  }

  const handleModalClose = () => {
    const lastNail = props.orderList[props.orderList.length - 1];
    getPurchasedNailData(lastNail);
    setShowAlert(false);
  }

  const handleZoom = (e) => {
    props.handleZoomed();
    d3.select('#svg #g')
      .attr('transform', e.transform);
  }

  const handleClick = (e, nail) => {
    const selectedData = {
      isSelected: true,
      background: nail.background,
      number: nail.number,
      price: '',
      x: nail.x,
      y: nail.y,
      status: 'available'
    };

    d3.select('#three')
      .remove()

    if (nail.bought || nail.fake_bought || nail.in_process || nail.offline_purchase) {
      selectedData.status = 'purchased';
      getPurchasedNailData(nail);
    }

    dispatch(setSelected(selectedData));
    setFocused(nail);
  }

  const renderSelectedNails = (number, x, y) => {
    return (
      <svg key={number}>
        <path transform={`translate(${linearScaleX(x) - 10} ${linearScaleY(y) - 10})`} d="M6.57932 22.4224L0.171875 0L23.3541 6.21166L6.57932 22.4224ZM2.31671 2.07838L7.33115 19.6165L20.4429 6.93526L2.31671 2.07838Z" fill="#D5C493"/>
        <circle  cx={linearScaleX(x)} cy={linearScaleY(y)} r={2} fill={'#CCCCCC'} />
      </svg>
    )
  }

  const renderBoughtNails = (boughtNail) => {
    const nail = nails.find((item) => item.number == boughtNail);

    return (
      <svg key={boughtNail}>
        <circle className='pulse' cx={linearScaleX(screenWidth <= 800 ? nail.x + 4 : nail.x + 3)} cy={linearScaleY(nail.y + 1.4)} r={3} strokeWidth={0} stroke={'#48B913'} fill={'#48B913'} />
      </svg>
    )
  }

  let zoom = d3.zoom()
    .scaleExtent([1, 50])
    .translateExtent([[0, 0], [screenWidth, screenHeihgt]])
    .on('zoom', handleZoom);

  let linearScaleX = d3.scaleLinear()
    .domain(xExtent)
    .range([0, innerWidth]);

  let linearScaleY = d3.scaleLinear()
    .domain(yExtent)
    .range([innerHeight, 0]);

  const loadData = () => {
    if (!props.isOrder) {
      d3.select('#svg #g')
        .selectAll('text')
        .data(nails)
        .join('text')
          .attr('id', (n) => `nail${n.number}`)
          .attr('dx', (n) => boughtNails.includes(n.number) ? linearScaleX(n.x) - 0.95: linearScaleX(n.x))
          .attr('dy', (n) => boughtNails.includes(n.number) ? linearScaleY(n.y) + 0.1: linearScaleY(n.y))
          .attr('fill', (n) => boughtNails.includes(n.number) ? '#48B913' : (n.fake_bought || n.bought || n.in_process || n.offline_purchase) ? '#C6B689' : '#CCCCCC')
          .attr('font-size', (n) => boughtNails.includes(n.number) ? '1.6px' : screenWidth < 1025 ? '0.6px' : '0.8px')
          .attr('font-weight', 400)
          .attr('textRendering', 'optimizeLegibility')
          .text((n) => addZeroBeforeNumber(n.number))
          .on('click', handleClick);
    }
  }
    
  useEffect(() => {
    loadData();

    d3.select('#svg #back')
      .call(zoom);
  }, []);

  useEffect(() => {
    if (!props.selectedData) {
      if (prevFocused) {
        d3.select(`#svg #g #nail${prevFocused.number}`)
          .attr('dx', boughtNails.includes(prevFocused.number) ? linearScaleX(screenWidth > 1024 ? prevFocused.x - 2 : prevFocused.x): linearScaleX(prevFocused.x))
          .attr('dy', boughtNails.includes(prevFocused.number) ? linearScaleY(screenWidth > 1024 ? prevFocused.y - 0.3 : prevFocused.y): linearScaleY(prevFocused.y))
          .attr('fill', boughtNails.includes(prevFocused.number) ? '#48B913' : (prevFocused.fake_bought || prevFocused.bought || prevFocused.in_process || prevFocused.offline_purchase) ? '#C6B689' : '#CCCCCC')
          .attr('font-size', boughtNails.includes(prevFocused.number) ? '1.6px' : screenWidth > 1024 ? '0.8px' : '0.6px')
          .attr('font-weight', 400)
      }
  
      d3.select(`#svg #g #nail${focused.number}`)
        .attr('dx', linearScaleX(screenWidth > 1024 ? focused.x - 2 : focused.x))
        .attr('dy', linearScaleY(screenWidth > 1024 ? focused.y - 0.3 : focused.y))
        .attr('fill', boughtNails.includes(focused.number) ? '#48B913' : '#C6B689')
        .attr('font-size', screenWidth > 1024 ? '1.6px' : '0.8px')
        .attr('font-weight', screenWidth > 1024 ? 400 : 700)


      orderList.map((item) => {
        d3.select(`#svg #g #nail${item.number}`)
          .attr('fill', '#48B913')
      })
      
      setPrevFocused(focused);
    }
  }, [focused]);

  useEffect(() => {
    if (removedOrderedNail) {
      d3.select(`#svg #g #nail${removedOrderedNail.number}`)
        .attr('fill', '#CCCCCC')
    }
  }, [removedOrderedNail])

  useEffect(() => {
    let filteredData = props.nails;

    if (!filtersState.personalPurchase && !filtersState.corporatePurchase) {
      filteredData = props.nails;
    }

    if (filtersState.onlyAvailable) {
      filteredData = filteredData.filter((item) => !item.fake_bought && !item.bought && !item.in_process && !item.offline_purchase);
    }

    if (filtersState.personalPurchase && !filtersState.corporatePurchase) {
      filteredData = filteredData.filter((item) => !item.background);
    }

    if (!filtersState.personalPurchase && filtersState.corporatePurchase) {
      filteredData = filteredData.filter((item) => item.background);
    }

    nails = filteredData;
    loadData();
  }, [filtersState]);

  useEffect(() => {
    if (props.orderList) {
      const isFace = props.orderList.some((item) => !item.background);
      const isBackground = props.orderList.some((item) => item.background);
  
      if (isBackground > 0 && stateOfSelectedData.background == false) {
        setModalText( `You want to buy nails in the face area, the previously added ${props.orderList ? props.orderList.length : null} nails will be removed. Remove nails ?`);
        setShowAlert(true);
      }
      
      if (isFace > 0 && stateOfSelectedData.background == true) {
        setModalText(`You want to buy nails in the background area, the previously added ${props.orderList ? props.orderList.length : null} nails will be removed. Remove nails ?`);
        setShowAlert(true);
      }
    }
  }, [stateOfSelectedData]);

  useImperativeHandle(ref, () => ({
    zoomIn() {
      props.handleZoomed();
      d3.select('#svg #back')
        .transition()
        .delay(200)
        .duration(1000)
        .call(zoom.scaleBy, 2, [width / 2, height / 2]);
    },
    zoomOut() {
      props.handleZoomed();
      d3.select('#svg #back')
        .transition()
        .delay(200)
        .duration(1000)
        .call(zoom.scaleBy, 0.5, [width / 2, height / 2]);
    },
    search(value) {
      if (value == '') return;

      let nail = nails.find((item) => item.number == value);

      const selectedData = {
        isSelected: true,
        background: nail.background,
        number: nail.number,
        price: '',
        x: nail.x,
        y: nail.y,
        status: 'available'
      };

      d3.select('#svg #back').transition().duration(2500).call(
        zoom.transform,
        d3.zoomIdentity.translate(width / 2, height / 2).scale(30).translate(-linearScaleX(nail.x), -linearScaleY(nail.y))
      );

      if (nail.bought || nail.fake_bought || nail.in_process || nail.offline_purchase) {
        getPurchasedNailData(nail);
      }
      
      dispatch(setSelected(selectedData));
      setPrevFocused(focused);
      setFocused(nail);

      props.handleZoomed();
    }
  }));

  return (
    <div className='container'>
      <ModalAlert
        isShow={showAlert}
        text={modalText}
        withAction={true}
        actionText='ok'
        cancelText='cancel'
        handleAction={handleModalAction}
        handleClose={handleModalClose}
      />
      <svg id='svg' width={svgWidth} height={svgHeight}>
        <g id='back' transform={!props.selectedData ? `translate(${(screenWidth / 2) - (innerWidth / 2)} ${screenWidth > 834 ? 148 : 45})` : `translate(${(screenWidth >= 360 && screenWidth < 800) ? ((screenWidth / 2) - (innerWidth / 2)) : 'none'})`}>
          <g id='g'>
            { 
              props.selectedData && 
                <svg id='two'>
                  { props.selectedData.map((item) => renderSelectedNails(item.id, item.x, item.y) ) }
                </svg>
            }

            {
              (props.numbers || focused)&&
                <svg id='three'>
                  { props.numbers.map((item) => renderBoughtNails(item) ) }
                </svg>
            }

            {
              (focused && boughtNails.includes(focused.number)) &&
                <svg id='four'>
                  <circle className='pulse' cx={linearScaleX(screenWidth <= 800 ? focused.x + 4 : focused.x + 3)} cy={linearScaleY(focused.y + 1.4)} r={3} strokeWidth={0} stroke={'#48B913'} fill={'#48B913'} />
                </svg>
            }

            <image
              href={backgroundImage}
              x={imageX}
              y={-2}
              width={innerWidth}
              height={innerHeight}/>
          </g>
        </g>
      </svg>
      {  
        (props.isOrder && screenWidth <= 800 ) && <div className='order-portrait__overlay_mobile'></div>
      }
    </div>
  )
})

SpotPortrait.displayName = 'SpotPortrait';
export default SpotPortrait;