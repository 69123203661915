import React, { useEffect } from 'react';

import './PaymentModal.scss';

import Button from '../Button/Button';

const PaymentModal = ({ data, isShow, handleClose }) => {
  if (!isShow) {
    return <></>;
  }

  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isShow]);

  return (
    <div className="payment-modal__overlay">
      <div className="payment-modal">
        <h3 className={'title'}>Thank you</h3>
        <p className="payment-modal__title">
          Your payment was successful! Within 72 hours, a certificate will be sent to your email. If
          you have not received your certificate within this time, you can go to the contact page
          for feedback with support
        </p>
        <div className="payment-content">
          <div className="payment-content__item">
            <span>Payer ID: </span>
            <span>{data.payerID}</span>
          </div>
          <div className="payment-content__item">
            <span>Payment status: </span>
            <span>success</span>
          </div>
        </div>
        <Button title="ok" type="primary" onClick={handleClose} />
      </div>
    </div>
  );
};

export default PaymentModal;
