import React, { useState } from 'react';
import './Company.scss';
import { useFormContext } from "react-hook-form";
import ReactHookFormInput from "../../../../../components/Inputs/Input/ReactHookFormInput";
import CustomPhoneInput from '../../../../../components/Inputs/CustomPhoneInput/CustomPhoneInput';

const Company = () => {
  const { register, setValue, formState: {errors} } = useFormContext();
  const [phoneNumber, setPhoneNumber] = useState();

  const handleChange = (e) => {
    setPhoneNumber(e);
    setValue('phone_number', `+${e}`);
  }

  return (
    <>
      <ReactHookFormInput
        label='company name'
        marked={true}
        name='name'
        placeholder={'Your full name'}
        warningMsg={'Please state your real name corresponding to your identity documents in Latin letters'}
        {...register("name")}
        errorMsg={errors?.name?.message}
      />
      <CustomPhoneInput
        label='contact phone number'
        value={phoneNumber}
        name='phone_number'
        onChange={handleChange}
        errorMsg={errors?.phone_number?.message}
      />
      <ReactHookFormInput
        label='contact email'
        marked={true}
        name='email'
        placeholder={'sample@sample.com'}
        maxLength={254}
        {...register("email")}
        errorMsg={errors?.email?.message}
      />

      <span className='input__info company__info'>
        {
          `Provide a valid email address! A certificate confirming participation in the "Golden Legacy" program and including 
            the data of purchased nails will be sent to the specified e-mail address.`
        }
      </span>

      <span className='second-step__text-info second-step__text-info_margin'>additional information</span>
      <p>Will also be displayed on the site and in the atlas of participants</p>
    </>
  )
}

export default Company;