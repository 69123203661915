import React, { useEffect, useRef, useState } from 'react';

import './Order.scss';

import Header from '../../components/Header/Header';
import OrderPortrait from './OrderPortrait/OrderPortrait';
import OrderSecondStep from './OrderSecondStep/OrderSecondStep';
import OrderFirstStep from './OrderFirstStep/OrderFirstStep';
import OrderThirdStep from './OrderThirdStep/OrderThirdStep';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import API from '../../API/API';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ModalAlert from '../../components/ModalAlert/ModalAlert';
import OrderSubmission from './OrderSubmission/OrderSubmission';
import EmailConfirm from './EmailConfirm/EmailConfirm';
import { useNavigate } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { resetNails, removeMultipleFromOrderList } from '../../store/cr7/nailsSlice';
import { resetOrderNails } from '../../store/cr7/orderFaceNailSlice';

const schema = yup.object({
  name: yup.string().required('This field is required'),
  email: yup.string().email('Fill in correct email').required('This field is required'),
  country: yup.string().required('This field is required'),
  city: yup.string().required('This field is required'),
  postcode: yup.string().required('This field is required'),
  address: yup.string().required('This field is required'),
  house_building: yup.string().required('This field is required')
});

const Order = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderRef = useRef();
  const recaptchaRef = useRef();

  const [isBackgroundNails, setIsBackgroundNails] = useState(false);
  const [minimumNailPrice, setMinimumNailPrice] = useState(100);

  const nails = useSelector((state) => state.orderFaceNail.nails);
  const isCompany = useSelector((state) => state.orderFaceNail.company);

  const [modalText, setModalText] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const [nailsModalText, setNailsModalText] = useState('');
  const [showNailsAlert, setShowNailsAlert] = useState(false);

  const [withDelivery, setWithDelivery] = useState(false);

  const [showEmailConfirm, setShowEmailConfirm] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [sendedEmail, setSendedEmail] = useState(false);

  const [takenNails, setTakenNails] = useState();

  const [nailNames, setNailNames] = useState();

  const orderedNails = useSelector((state) => state.orderFaceNail.nails);

  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { handleSubmit, getValues, reset } = methods;

  const handleModalAction = () => {
    dispatch(removeMultipleFromOrderList(takenNails));
    navigate('/CR7');
  };

  const confirmEmail = () => {
    if (
      !getValues('name') ||
      !getValues('email') ||
      !getValues('country') ||
      !getValues('city') ||
      !getValues('postcode') ||
      !getValues('address') ||
      !getValues('house_building')
    ) {
      return;
    }
    setDisabled(true);
    setSendedEmail(true);

    if (sendedEmail) return;

    const name = getValues('name');
    const temp = orderedNails.map((item) => item.name);

    temp.push(name);
    setNailNames(temp);

    const data = {
      email: getValues('email'),
      names: temp
    };

    API.order
      .sendCode(data)
      .then((response) => {
        console.log('response >>>', response);
        setShowEmailConfirm(true);
        setSendedEmail(false);
      })
      .catch((err) => {
        console.log('err >>>', err);
        for (let item in err.response.data) {
          setModalText(JSON.stringify(err.response.data[item][0]));
        }
        setShowAlert(true);
        setSendedEmail(false);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  const onSubmit = async (data) => {
    if (nails.length === 0) {
      setModalText('You should choose nails first');
      setShowAlert(true);
      return;
    }

    // eslint-disable-next-line no-unreachable
    if (nails.some((item) => item.price < minimumNailPrice || item.name.length < 1)) {
      setModalText('fill in all data for nails correctly at step2');
      setShowAlert(true);
      return;
    }

    // get recaptcha token
    const token = await recaptchaRef.current.executeAsync();
    if (!token) return;

    // prepare nails array;
    const finalNails = nails.map((item) => ({
      number: item.id,
      price: item.price,
      name: item.name,
      background: item.background
    }));

    data.nails = finalNails;
    data.company = isCompany;
    data.recaptcha = token;
    data.delivery = withDelivery;

    // send request to server
    API.order
      .nailBuy(data)
      .then((res) => {
        reset();
        dispatch(resetNails());
        dispatch(resetOrderNails());
        window.location.href = res.data;
      })
      .catch((err) => {
        if (typeof err === 'string') {
          setModalText(err);
          setShowAlert(true);
          return;
        }

        // error for bad words check
        if (
          err?.response?.data?.non_field_errors &&
          err?.response?.data?.non_field_errors.length &&
          err?.response?.data?.non_field_errors[0] === "Words shouldn't contain bad words"
        ) {
          setModalText("Your name or name on nail shouldn't contain bad words");
          setShowAlert(true);
          return;
        }

        // error for already bought nails
        if (err.response.status === 499) {
          const addingText = isBackgroundNails
            ? '. To make a purchase of background nails, you must select at least 7 nails. Choose another nails?'
            : '. Do you want to choose another nails?';
          setTakenNails(err.response.data);
          setNailsModalText(`Nails: ${err.response.data} already taken` + addingText);
          setShowNailsAlert(true);
          return;
        }

        const errorText = JSON.stringify(err?.response?.data);
        setModalText(errorText);
        setShowAlert(true);
      });
  };

  useEffect(() => {
    if (nails?.length) {
      const isBackground = nails.some((item) => item.background);
      setIsBackgroundNails(isBackground);
      setMinimumNailPrice(isBackground ? 500 : 100);
    }
  }, [nails]);

  return (
    <>
      <Header />
      <div className="order" ref={orderRef}>
        <div className={'order__nails'}>
          <p className={'order__title'}>Your nails</p>
          {<OrderPortrait />}
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={!emailConfirmed ? handleSubmit(emailConfirmed) : handleSubmit(onSubmit)}
            className="order__block">
            {/* dessctop */}
            {window.innerWidth > 1024 && (
              <Scrollbars autoHeight style={{ height: '78vh' }} autoHeightMin={'78vh'}>
                <OrderFirstStep
                  isBackgroundNails={isBackgroundNails}
                  setModalText={setModalText}
                  setShowAlert={setShowAlert}
                />
                <OrderSecondStep
                  isBackgroundNails={isBackgroundNails}
                  minimumNailPrice={minimumNailPrice}
                />
                <OrderThirdStep recaptchaRef={recaptchaRef} />

                <OrderSubmission
                  emailConfirmed={emailConfirmed}
                  withDelivery={withDelivery}
                  confirmEmail={confirmEmail}
                  setWithDelivery={setWithDelivery}
                  disabled={disabled}
                />

                <EmailConfirm
                  isShow={showEmailConfirm}
                  setIsShow={setShowEmailConfirm}
                  email={getValues().email}
                  setEmailConfirmed={setEmailConfirmed}
                  setModalText={setModalText}
                  setShowAlert={setShowAlert}
                  onSubmit={onSubmit}
                  names={nailNames}
                />
              </Scrollbars>
            )}

            {/* mobile */}
            {window.innerWidth < 1024 && (
              <>
                <OrderFirstStep
                  isBackgroundNails={isBackgroundNails}
                  setModalText={setModalText}
                  setShowAlert={setShowAlert}
                />
                <OrderSecondStep
                  isBackgroundNails={isBackgroundNails}
                  minimumNailPrice={minimumNailPrice}
                />
                <OrderThirdStep recaptchaRef={recaptchaRef} />

                <OrderSubmission
                  emailConfirmed={emailConfirmed}
                  withDelivery={withDelivery}
                  confirmEmail={confirmEmail}
                  setWithDelivery={setWithDelivery}
                  disabled={disabled}
                />

                <EmailConfirm
                  isShow={showEmailConfirm}
                  setIsShow={setShowEmailConfirm}
                  email={getValues().email}
                  setEmailConfirmed={setEmailConfirmed}
                  setModalText={setModalText}
                  setShowAlert={setShowAlert}
                  onSubmit={onSubmit}
                  names={nailNames}
                />
              </>
            )}
          </form>
        </FormProvider>
      </div>

      <ModalAlert isShow={showAlert} text={modalText} handleClose={() => setShowAlert(false)} />

      {/* modal for already bought nails */}
      <ModalAlert
        isShow={showNailsAlert}
        text={nailsModalText}
        handleClose={() => setShowNailsAlert(false)}
        withAction
        actionText={'yes'}
        cancelText={'no'}
        handleAction={handleModalAction}
      />
    </>
  );
};

export default Order;
