import React from 'react';

import './ExclusivePartner.scss';

import { ReactComponent as ExclusivePartnerIcon } from '../../assets/partners/exclusive-partner-icon.svg';

const ExclusivePartner = ({isCR7 = false}) => {
  return (
    <>
      {
        !isCR7
          ? <div className='exclusive-partner exclusive-partner__bordered'>
              <span>Exclusive partner</span>
              <ExclusivePartnerIcon />
              <span>Quatar Oil GMBH</span>
            </div>
          : <div className='exclusive-partner exclusive-partner__cr7'>
              <span>Exclusive partner</span>
              <ExclusivePartnerIcon />
              <span>Quatar Oil GMBH</span>
            </div>
      }

      <div className='exclusive-partner_mobile'>
        <ExclusivePartnerIcon />
      </div>
    </>
  )
}

export default ExclusivePartner;