import React, { useEffect, useState } from 'react';
import './HomePartners.scss';
import Partners from '../../../../components/Partners/Partners';
import Divider from '../../../../components/Divider/Divider';
import Button from '../../../../components/Button/Button';
import API from '../../../../API/API';
import BecomePartner from '../Modals/BecomePartner/BecomePartner';
import ModalAlert from '../../../../components/ModalAlert/ModalAlert';

const HomePartners = () => {
  const [info, setInfo] = useState({
    generalPartners: [],
    officialPartners: [],
    partners: []
  });
  const [showPartnersModal, setShowPartnersModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalText, setInfoModalText] = useState(false);

  const openPartnersModal = () => {
    setShowPartnersModal(true);
  };

  const handleInfoModalClose = () => {
    setShowInfoModal(false);
    setInfoModalText('');
  };

  useEffect(() => {
    API.home
      .partners()
      .then((res) => {
        setInfo({
          generalPartners: res?.data?.filter((item) => item.general_partner),
          officialPartners: res?.data?.filter((item) => item.official_partner),
          partners: res?.data?.filter((item) => item.partner)
        });
      })
      .catch((err) => {
        console.error('error getting mission data', err);
      });
  }, []);

  if (Object.values(info).every((item) => item.length === 0)) {
    return <></>;
  }

  return (
    <div id={'home-partners'} className="home-partners">
      <h2 className={'home-partners__title'}>Our Partners</h2>
      {info.generalPartners ? (
        <>
          <Partners
            className={'margin_mobile'}
            size={'lg'}
            title={'General Partners'}
            partners={info.generalPartners}
          />
          <div className={'divider-container'}>
            <Divider withMargin />
          </div>
        </>
      ) : null}

      {info.officialPartners ? (
        <>
          <Partners
            className={'margin_mobile'}
            size={'lg'}
            title={'Official Partners'}
            partners={info.officialPartners}
          />
          <div className={'divider-container'}>
            <Divider withMargin />
          </div>
        </>
      ) : null}

      {info.partners ? (
        <>
          <Partners size={'sm'} title={'Partners'} partners={info.partners} />
          <Button
            onClick={openPartnersModal}
            type={'primary'}
            className={'button'}
            title={'Become a partner'}
          />
          <div className={'divider-container'}>
            <Divider withMargin />
          </div>
        </>
      ) : null}

      <BecomePartner
        isShow={showPartnersModal}
        setIsShow={setShowPartnersModal}
        endPoint={API.home.becomePartner}
        setShowInfoModal={setShowInfoModal}
        setInfoModalText={setInfoModalText}
      />
      <ModalAlert isShow={showInfoModal} text={infoModalText} handleClose={handleInfoModalClose} />
    </div>
  );
};

export default HomePartners;
