import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromOrderList } from '../../store/cr7/nailsSlice';

import './Header.scss';

import OrderList from './OrderList/OrderList';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as FullLogo } from '../../assets/full-logo.svg';
import { ReactComponent as ArrowPrevIcon } from '../../assets/arrow-prev-icon.svg';
import { ReactComponent as OrderListIcon } from '../../assets/busket-icon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/twitter.svg';
import { ReactComponent as InstagramIcon } from '../../assets/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
import { useNavigate, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import useScrollDirection from '../../hooks/useScrollDirection';
import ExclusivePartner from '../ExclusivePartner/ExclusivePartner';
import API from '../../API/API';
import { motion, useAnimation } from 'framer-motion';

const burgerVariants = {
  hidden: { opacity: 0, x: '100vw' },
  visible: { opacity: 1, x: '0', transition: { duration: 0.5 } },
  exit: { x: '100vw', transition: { duration: 0.5 } }
};

const Header = ({
  isBackbutton,
  isShop,
  isFullLogo = true,
  aboutHashLink = '/home#home-program',
  partnersHashLink = '/home#home-partners'
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOrderList, setOrderList] = useState(false);
  const { scrollDirection } = useScrollDirection();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const controls = useAnimation();
  // const selectedData = useSelector((state) => state.nails.selectedData);
  const [contactsInfo, setContactsInfo] = useState({
    instagram: '',
    twitter: '',
    facebook: ''
  });

  const orderList = useSelector((state) => state.nails.orderList);

  const handleShowOrderList = () => {
    setOrderList(!isOrderList);
  };

  const handleRemoveOrderItem = (number) => {
    dispatch(removeFromOrderList(number));
  };

  const handleOnClose = () => {
    setOrderList(false);
  };

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleGoHome = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  const toggleOpenMenu = () => {
    if (isMenuOpen) {
      controls.start('exit');
    } else {
      controls.start('visible');
    }
    setIsMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    API.common
      .contacts()
      .then((res) => {
        setContactsInfo(res.data);
      })
      .catch((err) => {
        console.error('error getting footer data', err);
      });
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (isOrderList) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isOrderList]);

  const classNamesAnimations = `${
    scrollDirection === 'scroll-down' ? 'header_hidden' : 'header_visible'
  } ${'header_background'}`;
  const headerDesktopClassName = classNamesAnimations + ' header header_desktop';
  const headerMobileClassName = classNamesAnimations + ' header_mobile';

  return (
    <>
      <div className={headerDesktopClassName}>
        <div className="header__item header__item-border">
          {isBackbutton ? (
            <div onClick={handleGoBack} className="header__item header__back-button">
              <ArrowPrevIcon />
              <span>back</span>
            </div>
          ) : (
            <Link className={'header__item'} to={'/CR7'}>
              CR7
            </Link>
          )}
        </div>
        <HashLink smooth to={aboutHashLink} className="header__item header__item-border">
          about project
        </HashLink>
        <div className="header__item" onClick={handleGoHome}>
          {isFullLogo ? <FullLogo /> : <Logo />}
        </div>
        <HashLink smooth to={partnersHashLink} className="header__item header__item-border">
          partners
        </HashLink>
        <div className="header__item header__item-border">
          {isShop ? (
            <div className="header__order-list-button" onClick={handleShowOrderList}>
              <OrderListIcon />
              <span>nails</span>
              <span>{orderList.length > 0 ? orderList.length : 0}</span>
              {orderList.length > 0 && <span className="header__purchase-text">purchase now</span>}
            </div>
          ) : (
            <Link className={'links__text'} to={'/contacts'}>
              contacts
            </Link>
          )}
        </div>

        <OrderList
          isOrderList={isOrderList}
          orderList={orderList}
          onClose={handleOnClose}
          onRemoveItem={handleRemoveOrderItem}
        />
      </div>

      {/*mobile header*/}
      <div className={headerMobileClassName}>
        <div className="header__item header__item-border">
          <Link className={'header__item'} to={'/CR7'}>
            CR7
          </Link>
        </div>

        <div className="header__item header__item_centered" onClick={handleGoHome}>
          <Logo />
        </div>

        <div className="header__item header__item-border">
          {isShop && (
            <div className="header__order-list-button" onClick={handleShowOrderList}>
              <OrderListIcon />
              <span>{orderList.length > 0 ? orderList.length : 0}</span>
            </div>
          )}

          <p onClick={toggleOpenMenu} className={'header__item header__item_txt_right'}>
            menu
          </p>
        </div>
        <OrderList
          isOrderList={isOrderList}
          orderList={orderList}
          onClose={handleOnClose}
          onRemoveItem={handleRemoveOrderItem}
        />
      </div>

      {/* burger menu */}
      <motion.div
        animate={controls}
        initial="hidden"
        variants={burgerVariants}
        className={'header header__menu'}>
        <div className={'nav'}>
          <OrderListIcon />
          <button onClick={toggleOpenMenu} className={'nav__close'}>
            close
          </button>
        </div>

        <div className={'logo-container'}>
          <FullLogo />
        </div>

        <div className={'exclusive-container'}>
          <ExclusivePartner />
        </div>

        <div className={'links'}>
          <Link className={'links__text'} to={'/CR7'}>
            golden cristiano
          </Link>
          <Link className={'links__text'} to={'/statistic'}>
            country rank
          </Link>
          <Link className={'links__text'} to={'/participants'}>
            participant
          </Link>
          <Link className={'links__text'} to={'/contacts'}>
            contacts
          </Link>
          <HashLink onClick={toggleOpenMenu} smooth to={aboutHashLink} className="links__text">
            About
          </HashLink>
        </div>

        <div className="social">
          <a
            target={'_blank'}
            rel={'noreferrer'}
            href={contactsInfo.twitter}
            aria-label={contactsInfo.twitter}>
            <TwitterIcon />
          </a>
          <a
            target={'_blank'}
            rel={'noreferrer'}
            href={contactsInfo.instagram}
            aria-label={contactsInfo.instagram}>
            <InstagramIcon />
          </a>
          <a
            target={'_blank'}
            rel={'noreferrer'}
            href={contactsInfo.facebook}
            aria-label={contactsInfo.facebook}>
            <FacebookIcon />
          </a>
        </div>
      </motion.div>
    </>
  );
};

export default Header;
