import React, { useState, useEffect } from 'react';
import './HomeAuthor.scss';
import API from '../../../../API/API';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

import slider1 from '../../../../assets/home/author-slider-1.png';
import slider2 from '../../../../assets/home/author-slider-2.png';
import Author from '../../../../assets/home/author.png';
import {
  SliderNextButton,
  SliderPrevButton
} from '../../../../components/Slider/SliderButtons/SliderButtons';
import Divider from '../../../../components/Divider/Divider';
import DomPurify from '../../../../components/sanitizeHtml/DomPurify';
import useMotion from '../../../../hooks/useMotion';
import { motion } from 'framer-motion';
import bottomVariant from '../../../../animations/bottomVariant';

const HomeAuthor = () => {
  const { controls, ref } = useMotion();
  const [swiper, setSwiper] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeId, setActiveId] = useState(1);

  const [info, setInfo] = useState({
    title: 'Some artworks',
    description:
      'The works of Azamat Janaliev were portraits made of brass nails - Manny Pacquiau, Mike Tyson, Son Hin Myng, Pele, Diego Maradona, the Emir of Dubai Mohammed ibn Rashid al Maktum, Sheikh Hamdan ibn Mohammed al Maktum, Chingiz Aitmatov, the President of Mongolia Khaltmaagain Battulga and many other distinguished people of our time.',
    second_description:
      'The founder is the author of the project himself and contemporary art artist Azamat Dzhanaliev.',
    images: [
      {
        id: 1,
        src: slider1
      },
      {
        id: 2,
        src: slider2
      }
    ]
  });

  useEffect(() => {
    API.home
      .aboutAuthor()
      .then((res) => {
        setInfo(res?.data);
        if (res?.data?.images[0]?.id) {
          setActiveId(res?.data?.images[0]?.id);
        }
      })
      .catch((err) => {
        console.error('error getting mission data', err);
      });
  }, []);

  const handlePrevClick = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  return (
    <div ref={ref} className="home-author">
      <motion.h2 animate={controls} initial="hidden" variants={bottomVariant} className="title">
        Author
      </motion.h2>
      <motion.div
        animate={controls}
        initial="hidden"
        variants={bottomVariant}
        className="text text_lg">
        <DomPurify dirtyText={info.description} />
      </motion.div>

      <motion.div
        animate={controls}
        initial="hidden"
        variants={bottomVariant}
        className="text text_sm text_margin">
        <DomPurify dirtyText={info.second_description} />
      </motion.div>

      <motion.img
        animate={controls}
        initial="hidden"
        variants={bottomVariant}
        className="author-logo"
        src={Author}
        alt="author"
      />

      <div className="slider-wrapper">
        <div className="slider-content">
          <motion.p
            animate={controls}
            initial="hidden"
            variants={bottomVariant}
            className="slider-title">
            Some artworks
          </motion.p>
          <Swiper
            spaceBetween={50}
            loop
            slidesPerView={1}
            onSlideChange={(slider) => {
              setActiveId(info.images[slider.realIndex].id);
            }}
            onInit={(swiper) => setSwiper(swiper)}
            modules={[FreeMode, Navigation, Thumbs]}
            thumbs={{ swiper: thumbsSwiper }}>
            {info.images.map((item, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <img className="slider-image" src={item.image} alt="author" />
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="slider-nav">
            <div className={'button'}>
              <SliderPrevButton onClick={handlePrevClick} />
            </div>
            <div className="slider-dots">
              {info.images.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={`dot ${+item.id === +activeId ? 'dot_active' : ''}`}
                  />
                );
              })}
            </div>
            <div className={'button'}>
              <SliderNextButton onClick={handleNextClick} />
            </div>
          </div>
        </div>

        <div className="slider-divider" />

        <div className="thumbs">
          <Swiper
            spaceBetween={25}
            modules={[FreeMode, Navigation, Thumbs]}
            watchSlidesProgress
            onSwiper={setThumbsSwiper}
            loop
            slidesPerView={7}
            breakpoints={{
              2560: {
                slidesPerView: 7
              },
              1350: {
                slidesPerView: 6,
                spaceBetween: 20
              },
              1150: {
                slidesPerView: 5
              },
              950: {
                slidesPerView: 4
              },
              750: {
                slidesPerView: 3
              },
              550: {
                slidesPerView: 2
              }
            }}>
            {info.images.map((item) => {
              return (
                <SwiperSlide key={item.id}>
                  <img
                    className={`thumbs__image ${
                      +item.id === +activeId ? 'thumbs__image_active' : ''
                    }`}
                    src={item.image}
                    alt="author"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      <div className="divider-container">
        <Divider withMargin />
      </div>
    </div>
  );
};

export default HomeAuthor;
