const starVariant = {
  hidden: {
    opacity: 0,
    scale: 5,
    pathLength: 0,
    rotate: -180,
  },
  visible: {
    opacity: 1,
    scale: 1,
    pathLength: 1,
    rotate: 0,
    transition: { duration: 0.5, ease: 'easeInOut' },
  },
}

export default starVariant;