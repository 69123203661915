import React from 'react';

import './ToggleBar.scss';

const ToggleBar = ({isCompany, handleTogglePerson, handleToggleCompany}) => {
  return (
    <div className='toggle-bar'>
      <div
        className={`toggle-bar__item ${ isCompany ? 'toggle-bar__item_disabled' : 'toggle-bar__item_active'}`}
        onClick={handleTogglePerson}>
          Person
      </div>
      <div
        className={`toggle-bar__item ${ isCompany ? 'toggle-bar__item_active' : 'toggle-bar__item_disabled'}`}
        onClick={handleToggleCompany}>
          Company
      </div>
    </div>
  )
}

export default ToggleBar;