import React, { useEffect, useState } from 'react';
import './Participants.scss';
import Header from "../../components/Header/Header";
import { getCode } from "country-list";
import { useSelector } from 'react-redux';
import ReactCountryFlag from "react-country-flag";
import Button from '../../components/Button/Button';
import API from "../../API/API";

import { ReactComponent as TwitterIcon } from '../../assets/twitter.svg';
import { ReactComponent as InstagramIcon } from '../../assets/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';

const Participants = () => {
  const _offset = 50;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0)
  const [participants, setParticipants] = useState([])
  const countries = useSelector((state) => state.countriesData.countries);

  const getCountryCode = (countryName) => {
    return countries.find(item => item.country == countryName).iso2;
  }

  useEffect(() => {
    API.participants.getParticipants(currentPage + 1, _offset)
      .then(res => {
        setPageCount(Math.ceil(res.data.count / _offset))
        setParticipants(participants.concat(res.data.results))
      })
      .catch(err => {
        console.log("err", err)
        setPageCount(false)
      })
  }, [currentPage, _offset])

  return (
    <>
      <Header
        aboutHashLink={'/CR7#CR7artwork'}
        isBackbutton={true}
        isShop={true}
        partnersHashLink={'/CR7#golden-cristiano-partners'}
      />

      <div className='participants'>
        <h2>Guardians of Heritage</h2>
        <table>
          <tbody>
          {participants && participants.length ? (
            participants.map(item => (
              // TODO add normal key from id
              <tr key={item.name + item.email + Math.random()}>
                <td className={'td-nail'}>
                  {/* TODO add user nail (currently no data in backend)*/}
                  <p className={'text text_gold'}>{item.nails.map((item) => item.number).join(', ')}</p>
                </td>
                <td className={'text td-name'}>{item.name}</td>
                <td className={'td-info'}>
                  { item.twitter &&
                      <>
                        <div className={'participants__dot participants__dot_mobile'}/>
                        <a className={'text text_gold'} target={'_blank'} rel={'noreferrer'}
                                href={item.twitter || '#'} aria-label={item.twitter}><TwitterIcon /></a>
                      </>
                  }

                  { item.instagram &&
                      <>
                        <div className={'participants__dot participants__dot_mobile'}/>
                        <a className={'text text_gold'} target={'_blank'} rel={'noreferrer'}
                          href={item.instagram || '#'} aria-label={item.instagram}><InstagramIcon /></a>
                      </>
                  }

                  { item?.facebook &&
                      <>
                        <div className={'participants__dot participants__dot_mobile'}/>
                        <a className={'text text_gold'} target={'_blank'} rel={'noreferrer'}
                          href={item.facebook || '#'} aria-label={item.facebook}><FacebookIcon /></a>
                      </>
                    }
                </td>
                <td className={'text td-country'}>
                  <div className='country-block'>
                    <ReactCountryFlag style={{ width: '27px', height: '20px' }} className={'flag'}
                                      countryCode={getCode(item.country) ? getCode(item.country) : getCountryCode(item.country)} svg alt={item.country} />
                    {item.country} 
                  </div>
                  <p className='text text_gold text_mobile'>{item.name}</p>
                </td>
              </tr>
            ))
          ) : null}
          </tbody>
        </table>

        { pageCount ? (
          <div className='show-more__button'>
            <Button title='Show more' type='info' onClick={() => setCurrentPage(currentPage + 1)} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Participants;