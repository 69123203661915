import React, { useEffect, useState } from 'react';
import Divider from '../../../../components/Divider/Divider';
import API from '../../../../API/API';

import './HomeMission.scss';

import IntroNails from '../../../../assets/home/home-introducing.png';

import { motion } from 'framer-motion';
import DomPurify from '../../../../components/sanitizeHtml/DomPurify';
import useMotion from '../../../../hooks/useMotion';
import bottomVariant from '../../../../animations/bottomVariant';
import imageOpacityVariant from '../../../../animations/imageOpacityVariant';

const HomeMission = () => {
  const { controls, ref } = useMotion();
  const [info, setInfo] = useState({
    title: 'Our mission',
    description: 'Immortalize names',
    image: null,
    text2: 'for thousands of years'
  });

  useEffect(() => {
    API.home
      .mission()
      .then((res) => {
        setInfo(res.data);
      })
      .catch((err) => {
        console.error('error getting mission data', err);
      });
  }, []);

  return (
    <div className="home-intro" ref={ref}>
      <div className="home-intro__blur" />
      <motion.div
        animate={controls}
        initial="hidden"
        variants={imageOpacityVariant}
        className="home-intro__image-container">
        {info.image && <img className="home-intro__image" src={info.image} alt="intro-nails" />}
      </motion.div>

      <motion.p
        animate={controls}
        initial="hidden"
        variants={bottomVariant}
        className="home-intro__title">
        {info.title}
      </motion.p>

      <div className="home-intro__text-container">
        <motion.div
          animate={controls}
          initial="hidden"
          variants={bottomVariant}
          className="home-intro__text">
          {/* <DomPurify dirtyText={info.description} className={'home-intro__text'} /> */}
          {info.description}
        </motion.div>

        <motion.p
          animate={controls}
          initial="hidden"
          variants={bottomVariant}
          className="home-intro__text home-intro__text_padding">
          {info.text2}
        </motion.p>
      </div>
      <div className={'divider-container'}>
        <Divider withMargin />
      </div>
    </div>
  );
};

export default HomeMission;
