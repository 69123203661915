import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  company: false,
  nails: [], // required
  nailsIsTouched: false, // helper, no need to send to back
}

const orderFaceNailSlice = createSlice({
  name: 'orderFaceNail',
  initialState: initialState,
  reducers: {
    setUserTypeForStepOne(state, action) {
      state.company = action.payload.company;
    },
    orderSecondStep(state, action) {
      state.nails = [...action.payload.nails];
    },
    setIsNailsTouched(state, action) {
      state.nailsIsTouched = action.payload.nailsIsTouched;
    },
    resetOrderNails(state) {
      state.nails = [];
    }
  }
})

export const {
  orderSecondStep,
  setIsNailsTouched,
  setUserTypeForStepOne,
  resetOrderNails
} = orderFaceNailSlice.actions;
export default orderFaceNailSlice.reducer;