import React, { useEffect, useState } from 'react';

import './GoldenCristianoMainInfo.scss';

import ReactPlayer from 'react-player';
import API from "../../../../API/API";
import Divider from "../../../../components/Divider/Divider";
import DomPurify from "../../../../components/sanitizeHtml/DomPurify";

import { ReactComponent as PlayButtonIcon } from '../../../../assets/play-button-icon.svg';
import WavyText from "../../../../components/WavyText/WavyText";
import useMotion from "../../../../hooks/useMotion";
import bottomVariant from "../../../../animations/bottomVariant";
import { motion } from 'framer-motion';

const GoldenCristianoMainInfo = () => {
  const { controls, ref } = useMotion();
  const [info, setInfo] = useState({
    title: 'the moment',
    description: 'As we all know Cristiano Ronaldo was brilliant in all teams he joined. But at the very beginning of his brilliant career at Manchester United, CR7 became an undisputed star of Barclays Premier League.',
    text2: 'We find very symbolic that he came back where his glorious path started. So we picked a portrait from countless CR7 photos the one was taken at MANU early career. This photography portrays best Ronaldo as a leader, strong personality and champions spirit.',
    youtube_link: 'https://www.youtube.com/watch?v=5hFd6zGkxLE'
  });

  useEffect(() => {
    API.nailMap.mainPageInfo()
      .then(res => {
        setInfo(res.data)
      })
      .catch(err => {
        console.error('error getting main info data', err)
      })
  }, []);

  const Video = ({className = ''}) => (
    <ReactPlayer
      className={`main-info__video-block ${className}`}
      url={info.youtube_link}
      width='100%'
      light
      playing
      pip={false}
      controls={false}
      playIcon={<PlayButtonIcon />}
    />
  )

  return (
    <div className='main-info' ref={ref}>
      <div className='main-info__content'>
        <div className='main-info__text-block'>
          <WavyText containerClassName={'main-info__title'} textClassName={'h3'} text={info.title} isLetterSplit/>
          <Video className={'main-info__video-block_mobile'}/>
          <motion.div animate={controls} initial="hidden" variants={bottomVariant} className={'text text_sm'}>
            <DomPurify dirtyText={info.description}/>
          </motion.div>
          <motion.div animate={controls} initial="hidden" variants={bottomVariant}
                    className={'text text_sm main-info-description'}>
            <DomPurify dirtyText={info.text2}/>
          </motion.div>
        </div>
        <Video className={'main-info__video-block_desktop'}/>
      </div>
      <Divider/>
    </div>
  )
}

export default GoldenCristianoMainInfo;