import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import './Textarea.scss';
import { useFormContext, Controller } from 'react-hook-form';

const ReactHookFormTextarea = ({ label, placeholder, errorMsg, name }) => {
  const { control } = useFormContext();

  return (
    <div className="textarea">
      <div className="textarea__content">
        <label>{label}</label>
        <Controller
          render={({ field }) => (
            <TextareaAutosize {...field} placeholder={placeholder} minRows={4} maxRows={4} />
          )}
          control={control}
          name={name}
        />
      </div>

      {errorMsg ? <span className="input__error">{errorMsg}</span> : null}
    </div>
  );
};

export default ReactHookFormTextarea;
