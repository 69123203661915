import React from 'react';
import DOMPurify from "dompurify";

const DomPurify = ({ dirtyText = '', className = '' }) => {
  const safeHTML = DOMPurify.sanitize(dirtyText, {
    ALLOWED_TAGS: ["p", "span", "div", "br", "ul", "li", "ol"],
    ALLOWED_ATTR: ["style"],
  });

  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: safeHTML }} />
  )
};

export default DomPurify;