import React from 'react';
import useDrawLine from "../../../../../hooks/useDrawLine";
import './LineArt.scss';
import { motion } from 'framer-motion';
import starVariant from "../../../../../animations/lineStarVariant";

const LineArt = () => {
  const { line, container, drawPercent } = useDrawLine(500);

  return (
    <div ref={container} className='artwork__line'>
      <svg width="1210" height="1069" viewBox="0 0 1210 1069" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path ref={line} d="M598.5 0V124L13 398.5V273L1196 645.5V495L861.5 759.5L596.5 968.5V1069" stroke="#D5C493"/>

        {/* FIRST star*/}
        {drawPercent > 3.96 ? (
          <motion.g variants={starVariant} initial='hidden' animate='visible' filter="url(#filter0_d_1802_517)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M593.757 120.243C596.101 122.586 599.899 122.586 602.243 120.243C599.899 122.586 599.899 126.385 602.243 128.728C599.899 126.385 596.101 126.385 593.757 128.728C596.101 126.385 596.101 122.586 593.757 120.243Z"
                  fill="#FFFFD3"/>
          </motion.g>
        ) : null}

        {/* THIRD star*/}
        {drawPercent > 28.47 ? (
          <motion.g variants={starVariant} initial='hidden' animate='visible' filter="url(#filter1_d_1802_517)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.24271 269.243C11.5859 271.586 15.3848 271.586 17.728 269.243C15.3848 271.586 15.3848 275.385 17.728 277.728C15.3848 275.385 11.5859 275.385 9.24271 277.728C11.5859 275.385 11.5859 271.586 9.24271 269.243Z"
                  fill="#FFFFD3"/>
          </motion.g>
        ) : null}

        {/* SECOND star*/}
        {drawPercent > 24.17 ? (
          <motion.g variants={starVariant} initial='hidden' animate='visible' filter="url(#filter2_d_1802_517)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.24271 395.243C11.5859 397.586 15.3848 397.586 17.728 395.243C15.3848 397.586 15.3848 401.385 17.728 403.728C15.3848 401.385 11.5859 401.385 9.24271 403.728C11.5859 401.385 11.5859 397.586 9.24271 395.243Z"
                  fill="#FFFFD3"/>
          </motion.g>
        ) : null}

        {/* FOURTH star*/}
        {drawPercent > 33.67 ? (
          <motion.g variants={starVariant} initial='hidden' animate='visible' filter="url(#filter3_d_1802_517)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M168.243 319.243C170.586 321.586 174.385 321.586 176.728 319.243C174.385 321.586 174.385 325.385 176.728 327.728C174.385 325.385 170.586 325.385 168.243 327.728C170.586 325.385 170.586 321.586 168.243 319.243Z"
                  fill="#FFFFD3"/>
          </motion.g>
        ) : null}

        {/* SIXTH star*/}
        {drawPercent > 72.6 ? (
          <motion.g variants={starVariant} initial='hidden' animate='visible' filter="url(#filter4_d_1802_517)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1192.24 490.243C1194.59 492.586 1198.38 492.586 1200.73 490.243C1198.38 492.586 1198.38 496.385 1200.73 498.728C1198.38 496.385 1194.59 496.385 1192.24 498.728C1194.59 496.385 1194.59 492.586 1192.24 490.243Z"
                  fill="#FFFFD3"/>
          </motion.g>
        ) : null}

        {/* FIFTH star*/}
        {drawPercent > 67.70 ? (
          <motion.g variants={starVariant} initial='hidden' animate='visible' filter="url(#filter5_d_1802_517)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1192.24 641.243C1194.59 643.586 1198.38 643.586 1200.73 641.243C1198.38 643.586 1198.38 647.385 1200.73 649.728C1198.38 647.385 1194.59 647.385 1192.24 649.728C1194.59 647.385 1194.59 643.586 1192.24 641.243Z"
                  fill="#FFFFD3"/>
          </motion.g>
        ) : null}

        {/* SEVENTH star*/}
        {drawPercent > 78.1 ? (
          <motion.g variants={starVariant} initial='hidden' animate='visible' filter="url(#filter6_d_1802_517)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1056.24 598.243C1058.59 600.586 1062.38 600.586 1064.73 598.243C1062.38 600.586 1062.38 604.385 1064.73 606.728C1062.38 604.385 1058.59 604.385 1056.24 606.728C1058.59 604.385 1058.59 600.586 1056.24 598.243Z"
                  fill="#FFFFD3"/>
          </motion.g>
        ) : null}

        <defs>
          <filter id="filter0_d_1802_517" x="584.757" y="111.243" width="26.4854" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_517"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1802_517" result="shape"/>
          </filter>
          <filter id="filter1_d_1802_517" x="0.242676" y="260.243" width="26.4854" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_517"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1802_517" result="shape"/>
          </filter>
          <filter id="filter2_d_1802_517" x="0.242676" y="386.243" width="26.4854" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_517"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1802_517" result="shape"/>
          </filter>
          <filter id="filter3_d_1802_517" x="159.243" y="310.243" width="26.4854" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_517"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1802_517" result="shape"/>
          </filter>
          <filter id="filter4_d_1802_517" x="1183.24" y="481.243" width="26.4854" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_517"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1802_517" result="shape"/>
          </filter>
          <filter id="filter5_d_1802_517" x="1183.24" y="632.243" width="26.4854" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_517"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1802_517" result="shape"/>
          </filter>
          <filter id="filter6_d_1802_517" x="1047.24" y="589.243" width="26.4854" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1802_517"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1802_517" result="shape"/>
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default LineArt;