import React from 'react';
import Button from '../Button/Button';

import './ModalAlert.scss';

import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import ModalContainer from '../ModalContainer/ModalContainer';

/*
  text - required (request errors or warning text)
  withAction - flag (boolean) of action button (custom action handler)
  actionText - text for action button
  cancelText - text for cancel button
  handleAction - custom action handler method
  handleClose - close handler method
  isShow - flag (boolean)
*/

const ModalAlert = ({
  text,
  withAction = false,
  actionText,
  cancelText = 'ok',
  handleAction,
  handleClose,
  isShow = false
}) => {
  return (
    <ModalContainer isShow={isShow}>
      <div className="modal-alert__block">
        <CloseIcon onClick={handleClose} />
        {text}
        <div className="modal-alert__divider"></div>
        <div
          className={
            withAction
              ? 'modal-alert__actions modal-alert__align-between'
              : 'modal-alert__actions modal-alert__align-center'
          }>
          {withAction && <Button title={actionText} type="primary" onClick={handleAction} />}
          <Button title={cancelText} type="primary" onClick={handleClose} />
        </div>
      </div>
    </ModalContainer>
  );
};

export default ModalAlert;
