import React, { useState, useEffect } from 'react';
import API from '../../API/API';

import './Statistic.scss';

import Header from '../../components/Header/Header';
import StatisticTable from './Components/StatisticTable/StatisticTable';

const Statistic = () => {
  const [showStatistic, setShowStatistic] = useState(false);
  const [countryStats, setCountryStats] = useState();

  useEffect(() => {
    API.nailMap.countryStats()
    .then(res => {
      setCountryStats(res.data.sort((a, b) => a.total_purchaces < b.total_purchaces ? 1 : -1));
      setTimeout(() => setShowStatistic(true), 600);
    })
    .catch(error => {
      console.error('error getting country statistics data', error)
    })
  }, []);

  return (
    <>
      <Header
        aboutHashLink={'/CR7#CR7artwork'}
        isBackbutton={true}
        isShop={true}
        partnersHashLink={'/CR7#golden-cristiano-partners'} />

      <div className='statistic'>
        <h2>country chart</h2>

        { showStatistic && <StatisticTable data={countryStats} /> }
      </div>
    </>
  )
}

export default Statistic;