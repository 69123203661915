import React, { useState, useEffect } from 'react';

import './GoldenCristianoArtwork.scss';

import API from '../../../../API/API';
import DomPurify from '../../../../components/sanitizeHtml/DomPurify';

import Button from '../../../../components/Button/Button';

import artworkImage from '../../../../assets/golden-cristiano/artwork-image.png';
import LineArt from './LineArt/LineArt';
import WavyText from '../../../../components/WavyText/WavyText';

const GoldenCristianoArtwork = () => {
  const [info, setInfo] = useState({
    title2:
      'The Greatest of All Time, the holder of unbeatable all time records in football world ',
    about_picture: {
      title: 'Artwork',
      description:
        'The most followed influencer, Cristiano Ronaldo also known as CR7 is a true champion and inspiration for millions around the world. ',
      text2:
        'This symbolic number is an homage to shirt number of CR7. For our consequent 7 artworks, we use italian fine marble to make tribute to sculptures and architecture from the Renaissance epoch.',
      image: artworkImage
    }
  });

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    API.nailMap
      .mainPageInfo()
      .then((res) => {
        setInfo(res.data);
      })
      .catch((err) => {
        console.error('error getting main info data', err);
      });
  }, []);

  return (
    <div className="artwork">
      <WavyText
        textClassName="h2 text-center"
        text={info?.title2}
        duration={0.1}
        childrenDelay={0.05}
      />

      <div className="artwork__content" id={'CR7artwork'}>
        <WavyText
          containerClassName={'artwork__content-title'}
          textClassName="h3 text-center"
          text={info?.about_picture?.title}
          isLetterSplit
          duration={0.1}
        />
        <div className="artwork__image-block">
          <DomPurify
            className={'artwork__image-block-description'}
            dirtyText={info?.about_picture?.description}
          />
          <img className="artwork__image" src={info?.about_picture?.image} alt="artwork" />
          <DomPurify
            className={'artwork__image-block-text'}
            dirtyText={info?.about_picture?.text2}
          />
        </div>
        <Button onClick={handleClick} type="primary" title="Run project" />
        <LineArt />
      </div>
    </div>
  );
};

export default GoldenCristianoArtwork;
