import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedData: {
    isSelected: false,
    background: null,
    number: null,
    price: '',
    x: null,
    y: null,
    status: null,
    owner: null,
    country: null,
    contacts: [],
    nails: []
  },
  filters: {
    personalPurchase: false,
    corporatePurchase: false,
    onlyAvailable: false
  },
  price: null,
  orderList: [],
  removedOrderedNail: null,
  nails: []
};

const nailsSlice = createSlice({
  name: 'nails',
  initialState: initialState,
  reducers: {
    setSelected(state, action) {
      state.selectedData.isSelected = action.payload.isSelected;
      state.selectedData.background = action.payload.background;
      state.selectedData.number = action.payload.number;
      state.selectedData.x = action.payload.x;
      state.selectedData.y = action.payload.y;
      state.selectedData.status = action.payload.status;
      state.selectedData.owner = action.payload.owner;
      state.selectedData.country = action.payload.country;
      state.selectedData.contacts = action.payload.contacts;
      state.selectedData.nails = action.payload.nails;
      state.price = action.payload.price;
    },
    setPrice(state, action) {
      state.price = action.payload;
    },
    setNails(state, action) {
      state.nails = action.payload;
    },
    resetNails(state) {
      state.nails = [];
    },
    setOrderList(state, action) {
      state.orderList.push({ ...action.payload });
    },
    clearOrderList(state) {
      state.orderList = [];
    },
    removeFromOrderList(state, action) {
      const nail = state.orderList.find((item) => item.number == action.payload);
      const index = state.orderList.findIndex((item) => item.number == action.payload);
      state.orderList.splice(index, 1);
      state.removedOrderedNail = nail;
    },
    removeMultipleFromOrderList(state, action) {
      action.payload.map((item) => {
        const index = state.orderList.findIndex((i) => i.number == item);
        state.orderList.splice(index, 1);
      })
    },
    setFilters(state, action) {
      state.filters = action.payload;
    }
  }
})

export const {setNails, resetNails, setSelected, setOrderList, clearOrderList, removeFromOrderList, removeMultipleFromOrderList, setPrice, setFilters} = nailsSlice.actions;
export default nailsSlice.reducer;