import React from 'react';
import './Checkbox.scss';

const Checkbox = ({ label = '', textClassName = '', onChange }) => {
  return (
    <label className={`checkbox ${textClassName}`}>
      {label}
      <input type="checkbox" onChange={onChange}/>
      <span className="checkmark"></span>
    </label>
  );
};

export default Checkbox;