import Axios from 'axios';

Axios.defaults.xsrfCookieName = 'csrftoken';
Axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const baseApiURL = process.env.REACT_APP_BASE_API_URL;

const apiInstance = Axios.create({
  baseURL: baseApiURL
});

export default {
  home: {
    mission: () => apiInstance.get(`/fund/mission/`),
    program: () => apiInstance.get(`/fund/about-program/`),
    programPortrets: () => apiInstance.get(`/fund/7-portrets/`),
    theName: () => apiInstance.get(`/fund/about-cr7/`),
    aboutAuthor: () => apiInstance.get(`/fund/about-author/`),
    partners: () => apiInstance.get(`/fund/funders/`),
    becomePartner: (data) => apiInstance.post(`/fund/funders/`, data)
  },

  common: {
    contacts: () => apiInstance.get(`/fund/contacts/`)
  },

  nailMap: {
    nails: () => apiInstance.get('/CR7/nails'),
    mainPageInfo: () => apiInstance.get('/CR7/main-page-info/'),
    historyBook: () => apiInstance.get('/CR7/history-book/'),
    buyersByNailNumber: (nail_number) => apiInstance.get(`/CR7/buyers/${nail_number}/`),
    partners: () => apiInstance.get('/CR7/partners/'),
    countryStats: () => apiInstance.get('/CR7/country-stats/'),
    becomePartner: (data) => apiInstance.post(`/CR7/funder-create/`, data)
  },

  participants: {
    getParticipants: (page, page_size) =>
      apiInstance.get(`/CR7/buyers/?page=${page}&page_size=${page_size}`)
  },

  order: {
    nailBuy: (body, headers) => apiInstance.post('/CR7/nail-buy/', body, headers),
    sendCode: (data) => apiInstance.post(`/CR7/email-verify/`, data),
    checkCode: (data) => apiInstance.post(`/CR7/code-verify/`, data),
    paymentResponse: (data) => apiInstance.post(`/CR7/payment-response/`, data)
  },
  orderThirdStep: {
    getCountry: (data) => apiInstance.get(`https://countriesnow.space/api/v0.1/countries`, data)
  }
};
