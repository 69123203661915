import React from 'react';

import './Button.scss';

import classNames from 'classnames';

const Button = ({title, type, onClick, className, isSubmit, isDisabled}) => {
  let classes = classNames({
    'button': true,
    className,
    'button-primary': type == 'primary',
    'button-primary_active': type == 'primary-active',
    'button-primary_grand': type == 'primary-grand',
    'button-info': type == 'info',
    'button-info_md': type == 'info_md',
    'button_disabled': isDisabled,
  })
  return (
    <button type={isSubmit ? 'submit': 'button'} className={classes} onClick={!isDisabled ? onClick : null}>
      {title}
    </button>
  )
}

export default Button;