import React, { useState, useEffect } from 'react';
import './OrderSubmission.scss';
import Button from "../../../components/Button/Button";
import Checkbox from "../../../components/Inputs/Checkbox/Checkbox";
import DHL from '../../../assets/order/DHL-delivery.svg';
import { useSelector } from "react-redux";

const _deliveryPrice = 55;

const OrderSubmission = ({withDelivery, setWithDelivery, disabled, emailConfirmed, confirmEmail}) => {
  const [total, setTotal] = useState(0);
  const nails = useSelector(state => state.orderFaceNail.nails);

  useEffect(() => {
    if (nails) {
      const nailsTotal = nails.reduce((acc, item) => acc += Number(item.price), 0);

      let deliveryPrice = 0;
      if (withDelivery) {
        deliveryPrice = _deliveryPrice;
      }

      setTotal(nailsTotal + deliveryPrice);
    }
  }, [withDelivery, nails])

  const handleChecked = (e) => {
    setWithDelivery(e.target.checked);
  }

  return (
    <div className='order-submission'>

      <p className={'description'}>We can print your certificate and ship it to you worldwide!</p>

      <div className={'price'}>
        <Checkbox onChange={handleChecked} label={'I want certificate delivery'} textClassName={'checkbox-text'} />
        <p className={`delivery-price ${withDelivery && 'delivery-price_active'}`}>+{_deliveryPrice}$</p>
      </div>

      <p className={'delivery-text'}>Delivery is handled by </p>

      <img className={'delivery-image'} src={DHL} alt="DHL delivery"/>

      <div className="divider"></div>

      <div className={'total'}>
        <p className={'total__text'}>TOTAL: </p>
        <p className={'total__price'}>${total}</p>
      </div>

      <div className='policy'>
        <p>By clicking the button below you agree to the&nbsp;</p>
        <a target={'_blank'} rel={'noreferrer'}
           href={'https://drive.google.com/file/d/1Ei1rRispV8HVE5SG1bRtto4MyvQvWqEg/view?usp=sharing '} aria-label='personal data processing policy'>
          personal data processing policy
        </a>
      </div>

      {emailConfirmed ? (
        <Button isSubmit type='primary-active' title='Proceed to payment'/>
      ) : (
        <Button isSubmit onClick={confirmEmail} isDisabled={disabled} type='primary' title='Confirm email'/>
      )}
    </div>
  );
};

export default OrderSubmission;