import React, { forwardRef } from 'react';

import './Select.scss';
import { useFormContext } from 'react-hook-form';

const ReactHookFormSelect = forwardRef((props) => {
  const { label, name, placeholder, errorMsg, data, warningMsg, marked = false } = props;
  const { register } = useFormContext();

  return (
    <div className="select-input">
      <div className="select-input__content">
        <label>
          {label}
          {marked && <span className="select__required">*</span>}
        </label>
        <select {...register(name)} defaultValue={''} className="select">
          <option value={''} disabled>
            {placeholder}
          </option>
          {data.map((item, index) => {
            return (
              <option key={index} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      </div>

      <span className="input__info">{warningMsg}</span>

      {errorMsg ? <span className="input__error">{errorMsg}</span> : null}
    </div>
  );
});

ReactHookFormSelect.displayName = 'ReactHookFormSelect';
export default ReactHookFormSelect;
