import React, { useState, useRef, useEffect } from 'react';
import API from '../../../../API/API';
import PuffLoader from 'react-spinners/PuffLoader';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from '../../../../hooks/useQuery';
import { setOrderList, setNails } from '../../../../store/cr7/nailsSlice';
import './GoldenCristianoPortrait.scss';

import plusIcon from '../../../../assets/plus-icon.png';
import minusIcon from '../../../../assets/minus-icon.png';
import { ReactComponent as SearchIcon } from '../../../../assets/SearchMenu.svg';

import Filters from './Components/Filters/Filters';
import NailInfo from './Components/NailInfo/NailInfo';
import Popup from '../../../../components/Popup/Popup';
import Button from '../../../../components/Button/Button';
import CountryChart from './Components/CountryChart/CountryChart';
import ModalAlert from '../../../../components/ModalAlert/ModalAlert';
import PaymentModal from '../../../../components/PaymentModal/PaymentModal';
import SpotPortrait from '../../../../components/SpotPortrait/SpotPortrait';
import ExclusivePartner from '../../../../components/ExclusivePartner/ExclusivePartner';
import FiltersBottomSheet from './Components/FiltersBottomSheet/FiltersBottomSheet';

const addZeroBeforeNumber = (number) => {
  const numberString = number.toString();
  return numberString.padStart(5, '0');
};

const renderNailInfoBlock = (
  selectedData,
  orderList,
  isPriceError,
  setPriceError,
  isNailInfo,
  setZoomed,
  handleSearch,
  handlePriceValidation,
  handleCloseNailInfo
) => {
  const dispatch = useDispatch();
  const [price, setPrice] = useState(null);
  const [isPopup, setPopup] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalText, setModalText] = useState();
  const [showFiltersBottomSheet, setShowFiltersBottomSheet] = useState(false);
  const priceLimit = selectedData.background ? 500 : 100;

  const handlePriceChange = (e) => {
    const value = e.target.value;
    setPrice(value);
  };

  const handleClosePopup = () => {
    setPopup(false);
  };

  useEffect(() => {
    if (!showFiltersBottomSheet) window.scrollTo(0, 0);
  }, [showFiltersBottomSheet]);

  useEffect(() => {
    if (selectedData) {
      setPopup(false);
      setPrice('');
    }
  }, [selectedData]);

  setTimeout(() => {
    setPopup(false);
  }, 10000);

  const handleClick = () => {
    const isExist = orderList.find((item) => item.number === selectedData.number);
    const isfaceNails = orderList.some((item) => !item.background);
    const faceNailsLimit = orderList.map((item) => !item.background).length;

    if (isExist) {
      setModalText('The nail has already been added to the cart');
      setShowAlert(true);
      return;
    }

    if (isfaceNails && faceNailsLimit >= 7) {
      setModalText('the maximum number of nails on the face has already been collected');
      setShowAlert(true);
      return;
    }

    if (price >= priceLimit) {
      const orderData = {
        number: selectedData.number,
        x: selectedData.x,
        y: selectedData.y,
        background: selectedData.background,
        price
      };
      handlePriceValidation(false);
      dispatch(setOrderList(orderData));
      handleCloseNailInfo();
      setPopup(true);
    } else {
      handlePriceValidation(true);
    }
  };

  const [checked, setChecked] = useState(false);
  const [height, setHeight] = useState();

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const handleMobileSearchClick = () => {
    setShowFiltersBottomSheet(true);
  };

  const handleShowKeyboard = () => {
    setPriceError(false);
    setHeight(window.pageYOffset);
  };

  const handleBlur = () => {
    if (window.innerWidth <= 800 && height < window.innerHeight) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setHeight(window.pageYOffset);
    }
  };
  return (
    <>
      {isPopup && (
        <Popup number={addZeroBeforeNumber(selectedData.number)} handleClose={handleClosePopup} />
      )}
      <ModalAlert isShow={showAlert} text={modalText} handleClose={() => setShowAlert(false)} />

      {window.innerWidth < 800 && (
        <div
          className={'golden-cristiano-portrait__search-icon__mobile'}
          onClick={handleMobileSearchClick}>
          <SearchIcon />
        </div>
      )}
      {!selectedData.isSelected && (
        <>
          <div className="nail-info__empty-block">
            <span className="nail-info__title">golden ronaldo</span>
            <div className="empty-block">
              <span className="nail-info__notice">Choose your nail</span>
            </div>
          </div>
          {window.innerWidth > 1024 && (
            <Filters checked={checked} setZoomed={setZoomed} handleSearch={handleSearch} />
          )}
        </>
      )}

      {selectedData.isSelected && (
        <>
          {window.innerWidth > 1024 && (
            <Filters checked={checked} setZoomed={setZoomed} handleSearch={handleSearch} />
          )}
          <NailInfo
            selectedData={selectedData}
            price={price}
            priceLimit={priceLimit}
            isNailInfo={isNailInfo}
            handlePriceChange={handlePriceChange}
            isPriceError={isPriceError}
            handleClick={handleClick}
            handleCloseNailInfo={handleCloseNailInfo}
            handleShowKeyboard={handleShowKeyboard}
            handleBlur={handleBlur}
          />
        </>
      )}

      {showFiltersBottomSheet ? (
        <FiltersBottomSheet
          open={showFiltersBottomSheet}
          setOpen={setShowFiltersBottomSheet}
          onChange={handleSearch}
        />
      ) : null}
    </>
  );
};

const renderListOfCounties = (navigate) => {
  const showStatistic = () => {
    navigate('/statistic');
  };

  return (
    <div className="nail-map__statistics">
      <div className="nail-map__statistics-block">
        <CountryChart />
        <Button title="view all" type="info" onClick={showStatistic} />
      </div>

      <div className="nail-map__exclusive-partner">
        <ExclusivePartner isCR7={true} />
      </div>
    </div>
  );
};

const GoldenCristianoPortrait = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const portraitRef = useRef(null);
  const data = useSelector((state) => state.nails.nails);
  const selectedData = useSelector((state) => state.nails.selectedData);
  const orderList = useSelector((state) => state.nails.orderList);
  const initialNail = data.find((item) => !item.fake_bought);
  const [loading, setLoading] = useState(true);
  const [isPriceError, setPriceError] = useState(false);
  const [isNailInfo, setNailInfo] = useState(true);
  const [isZoomed, setZoomed] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentModalData, setPaymentModalData] = useState();
  const token = query.get('token');
  const payerID = query.get('PayerID');
  const numbers = query.getAll('numbers').map((item) => parseInt(item));
  const available =
    data.length -
    data.filter((item) => item.bought || item.fake_bought || item.offline_purchase).length;

  useEffect(() => {
    if (data.length == 0) {
      API.nailMap
        .nails()
        .then((res) => {
          dispatch(setNails(res.data));
          setLoading(false);
        })
        .catch((err) => {
          console.error('error getting mission data', err);
        });
    } else {
      setLoading(false);
    }
  }, [data]);

  const override = {
    display: 'block',
    position: 'absolute',
    top: 'calc(50% - 24vh)',
    left: 'calc(50% - 75px)',
    margin: '0 auto',
    borderColor: '#C6B689'
  };

  const override2 = {
    display: 'block',
    position: 'absolute',
    width: window.innerWidth >= 280 && window.innerWidth <= 800 ? window.innerWidth - 28 : '500px',
    height: window.innerWidth >= 280 && window.innerWidth <= 800 ? window.innerWidth / 2 : '250px',
    bottom:
      window.innerWidth >= 280 && window.innerWidth <= 800
        ? window.innerHeight < 700
          ? 44
          : window.innerWidth / 4
        : '0',
    left:
      window.innerWidth >= 280 && window.innerWidth <= 800
        ? `calc(50% - ${(window.innerWidth - 28) / 2})`
        : 'calc(50% - 250px)',
    margin: '0 auto',
    borderColor: '#C6B689',
    opacity: 0.6,
    overflow: 'hidden'
  };

  const override3 = {
    display: 'block',
    position: 'absolute',
    width: window.innerWidth - 28,
    height: window.innerWidth / 2,
    left: window.innerWidth / 2 - (window.innerWidth - 56),
    bottom: 0,
    margin: '0 auto',
    borderColor: '#C6B689',
    opacity: 0.6,
    overflow: 'hidden'
  };

  useEffect(() => {
    if (selectedData.isSelected) {
      setNailInfo(false);
    }
  }, [selectedData]);

  useEffect(() => {
    if (window.innerWidth < 1000 && !isNailInfo) {
      document.body.style.overflow = 'hidden';
    } else if (window.innerWidth < 1000 && isNailInfo) {
      document.body.style.overflow = 'scroll';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isNailInfo]);

  useEffect(() => {
    if (token && payerID) {
      setShowPaymentModal(true);
      setPaymentModalData({ token, payerID });

      API.order
        .paymentResponse({ token })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [token]);

  const handleCloseNailInfo = () => {
    setNailInfo(true);
  };

  const handlePriceValidation = (value) => {
    setPriceError(value);
  };

  const handleZoomIn = () => {
    portraitRef.current.zoomIn();
  };

  const handleZoomOut = () => {
    portraitRef.current.zoomOut();
  };

  const handleSearch = (e) => {
    setTimeout(() => portraitRef.current.search(e.target.value), 1800);
  };

  const handleZoomed = () => {
    setZoomed(true);
  };

  return (
    <div className="nail-map">
      <PaymentModal
        isShow={showPaymentModal}
        data={paymentModalData}
        handleClose={() => setShowPaymentModal(false)}
      />
      {renderNailInfoBlock(
        selectedData,
        orderList,
        isPriceError,
        setPriceError,
        isNailInfo,
        setZoomed,
        handleSearch,
        handlePriceValidation,
        handleCloseNailInfo
      )}

      <div className="nail-map__portrait-wrapper">
        <span className="nail-map__title nail-map__mobile-header">golden ronaldo</span>
        <PuffLoader
          color={'#C6B689'}
          loading={loading}
          cssOverride={override}
          size={150}
          aria-label="Loading"
          data-testid="loader"
          speedMultiplier={0.7}
        />
        <div className="nail-map__portrait">
          {!loading && (
            <SpotPortrait
              ref={portraitRef}
              nails={data}
              isOrder={false}
              orderList={orderList}
              initialNail={initialNail}
              numbers={numbers}
              handleZoomed={handleZoomed}
            />
          )}
        </div>
      </div>

      {renderListOfCounties(navigate)}

      {!loading && (
        <>
          <div className="zoom-buttons">
            {!isZoomed && (
              <div className="nail-map__zoom-info_mobile"> Use buttons or mouse for zoom</div>
            )}
            <div
              className={isZoomed ? 'zoom-minus' : 'zoom-minus zoom-buttons__highlighted'}
              onClick={handleZoomOut}>
              <img src={minusIcon} alt="minus-icon" />
            </div>
            <div
              className={isZoomed ? 'zoom-plus' : 'zoom-plus zoom-buttons__highlighted'}
              onClick={handleZoomIn}>
              <img src={plusIcon} alt="plus-icon" />
            </div>

            {!loading && window.innerWidth >= 280 && window.innerWidth <= 800 && (
              <PuffLoader
                color={'#C6B689'}
                loading={!isZoomed}
                cssOverride={override3}
                size={
                  window.innerWidth >= 280 && window.innerWidth <= 800
                    ? window.innerWidth - 28
                    : 500
                }
                data-testid="loader"
                speedMultiplier={0.4}
              />
            )}
          </div>

          {window.innerWidth > 800 && (
            <PuffLoader
              color={'#C6B689'}
              loading={!isZoomed}
              cssOverride={override2}
              size={
                window.innerWidth >= 280 && window.innerWidth <= 800 ? window.innerWidth - 28 : 500
              }
              data-testid="loader"
              speedMultiplier={0.4}
            />
          )}
        </>
      )}

      <div className="nail-map__exclusive-partner-mobile">
        <ExclusivePartner />
      </div>
      <span className="exclusive-partner__text exclusive-partner__text_start">
        <span className="exclusive-partner__text exclusive-partner__text_gold exclusive-partner__text_start">
          {available}&nbsp;
        </span>
        of 7777 nails available
      </span>
      <span className="exclusive-partner__text">Exclusive partner</span>
    </div>
  );
};

export default GoldenCristianoPortrait;
