import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../../../../../store/cr7/nailsSlice';

import './Filters.scss';

import Switch from 'react-switch';
import Search from '../../../../../../components/Search/Search';
import CheckButton from '../../../../../../components/CheckButton/CheckButton';

const Filters = ({ setZoomed, handleSearch }) => {
  const dispatch = useDispatch();
  const filtersState = useSelector((state) => state.nails.filters);
  const data = useSelector((state) => state.nails.nails);
  const available =
    data.length -
    data.filter((item) => item.bought || item.fake_bought || item.offline_purchase).length;

  const handlePersonalPurchaseClick = () => {
    dispatch(
      setFilters({
        personalPurchase: !filtersState.personalPurchase,
        corporatePurchase: false,
        onlyAvailable: filtersState.onlyAvailable
      })
    );
  };

  const handleCorporatePurchaseClick = () => {
    dispatch(
      setFilters({
        personalPurchase: false,
        corporatePurchase: !filtersState.corporatePurchase,
        onlyAvailable: filtersState.onlyAvailable
      })
    );
  };

  const handleSwitch = () => {
    dispatch(
      setFilters({
        personalPurchase: filtersState.personalPurchase,
        corporatePurchase: filtersState.corporatePurchase,
        onlyAvailable: !filtersState.onlyAvailable
      })
    );
  };

  return (
    <div className="filters">
      <Search onChange={handleSearch} setZoomed={setZoomed} />

      <label>Show me available for:</label>

      <div className="filters__check-buttons">
        <CheckButton
          title="personal purchase"
          isActive={filtersState.personalPurchase}
          onClick={handlePersonalPurchaseClick}
        />
        <CheckButton
          title="corporate purchase"
          isActive={filtersState.corporatePurchase}
          onClick={handleCorporatePurchaseClick}
        />
      </div>

      <div className="filters__switcher">
        <Switch
          onChange={handleSwitch}
          checked={filtersState.onlyAvailable}
          className="react-switch"
          onColor="#48B913"
          offColor="#C6B689"
          onHandleColor="#000000"
          offHandleColor="#000000"
          checkedIcon={false}
          uncheckedIcon={false}
          handleDiameter={17}
          height={21}
          width={43}
        />
        <label className={filtersState.onlyAvailable ? 'filters__switcher_active' : ''}>
          only available for purchase
        </label>
        <label>({available} nails)</label>
      </div>
    </div>
  );
};

export default Filters;
