import React from 'react';
import './StepsLine.scss';
import { motion } from 'framer-motion';
import useDrawLine from "../../../../../hooks/useDrawLine";
import starVariant from "../../../../../animations/lineStarVariant";

const StepsLine = () => {
  const { line, container, drawPercent } = useDrawLine(500);

  return (
    <div ref={container} className={'home-program-steps-line'}>
      <svg width="861" height="3206" viewBox="0 0 861 3206" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          ref={line}
          d="M560.5 1L2 529L859 793L441.5 1029.5V889L588.5 978.5L109.5 1468L814 1717.5L375 2075.5L612 2200.5V2075.5L160 2237.5L673.5 2660.5L393 2997.5L464.5 3101V3205.5"
          stroke="#D5C493"
          strokeWidth="1.2"
        />

        {/* SECOND star*/}
        {drawPercent > 34.25 ? (
          <motion.g
            variants={starVariant}
            initial='hidden'
            animate='visible'
            filter="url(#filter0_d_1304_448)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M437.243 885.243C439.586 887.586 443.385 887.586 445.728 885.243C443.385 887.586 443.385 891.385 445.728 893.728C443.385 891.385 439.586 891.385 437.243 893.728C439.586 891.385 439.586 887.586 437.243 885.243Z"
              fill="#FFFFD3"/>
          </motion.g>
        ) : null}

        {/* FIRST star*/}
        {drawPercent > 32.15 ? (
          <motion.g
            variants={starVariant}
            initial='hidden'
            animate='visible'
            filter="url(#filter1_d_1304_448)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M437.243 1024.24C439.586 1026.59 443.385 1026.59 445.728 1024.24C443.385 1026.59 443.385 1030.38 445.728 1032.73C443.385 1030.38 439.586 1030.38 437.243 1032.73C439.586 1030.38 439.586 1026.59 437.243 1024.24Z"
              fill="#FFFFD3"/>
          </motion.g>
        ) : null}


        {/* THIRD star*/}
        {drawPercent > 36.9 ? (
          <motion.g
            variants={starVariant}
            initial='hidden'
            animate='visible'
            filter="url(#filter2_d_1304_448)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M585.243 974.243C587.586 976.586 591.385 976.586 593.728 974.243C591.385 976.586 591.385 980.385 593.728 982.728C591.385 980.385 587.586 980.385 585.243 982.728C587.586 980.385 587.586 976.586 585.243 974.243Z"
              fill="#FFFFD3"/>
          </motion.g>
        ) : null}

        {/* FOURth star*/}
        {drawPercent > 66.8 ? (
          <motion.g
            variants={starVariant}
            initial='hidden'
            animate='visible'
            filter="url(#filter3_d_1304_448)">
            <path
              fillRule="evenodd" clipRule="evenodd"
              d="M370.243 2071.24C372.586 2073.59 376.385 2073.59 378.728 2071.24C376.385 2073.59 376.385 2077.38 378.728 2079.73C376.385 2077.38 372.586 2077.38 370.243 2079.73C372.586 2077.38 372.586 2073.59 370.243 2071.24Z"
              fill="#FFFFD3"/>
          </motion.g>
        ) : null}

        {/* SIXTH star*/}
        {drawPercent > 72.7 ? (
          <motion.g
            variants={starVariant}
            initial='hidden'
            animate='visible'
            filter="url(#filter4_d_1304_448)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M607.243 2071.24C609.586 2073.59 613.385 2073.59 615.728 2071.24C613.385 2073.59 613.385 2077.38 615.728 2079.73C613.385 2077.38 609.586 2077.38 607.243 2079.73C609.586 2077.38 609.586 2073.59 607.243 2071.24Z"
              fill="#FFFFD3"/>
          </motion.g>
        ) : null}

        {/* FIFTH star*/}
        {drawPercent > 70.9 ? (
          <motion.g
            variants={starVariant}
            initial='hidden'
            animate='visible'
            filter="url(#filter5_d_1304_448)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M607.243 2195.24C609.586 2197.59 613.385 2197.59 615.728 2195.24C613.385 2197.59 613.385 2201.38 615.728 2203.73C613.385 2201.38 609.586 2201.38 607.243 2203.73C609.586 2201.38 609.586 2197.59 607.243 2195.24Z"
              fill="#FFFFD3"/>
          </motion.g>
        ) : null}


        {/* SEVENTH star*/}
        {drawPercent > 96.5 ? (
          <motion.g
            variants={starVariant}
            initial='hidden'
            animate='visible'
            filter="url(#filter6_d_1304_448)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M389.243 2993.24C391.586 2995.59 395.385 2995.59 397.728 2993.24C395.385 2995.59 395.385 2999.38 397.728 3001.73C395.385 2999.38 391.586 2999.38 389.243 3001.73C391.586 2999.38 391.586 2995.59 389.243 2993.24Z"
              fill="#FFFFD3"/>
          </motion.g>
        ) : null}


        {/* EIGHTH star*/}
        {drawPercent > 98.3 ? (
          <motion.g
            variants={starVariant}
            initial='hidden'
            animate='visible'
            filter="url(#filter7_d_1304_448)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M460.243 3096.24C462.586 3098.59 466.385 3098.59 468.728 3096.24C466.385 3098.59 466.385 3102.38 468.728 3104.73C466.385 3102.38 462.586 3102.38 460.243 3104.73C462.586 3102.38 462.586 3098.59 460.243 3096.24Z"
              fill="#FFFFD3"/>
          </motion.g>
        ) : null}

        <defs>
          {/* SECOND */}
          <filter id="filter0_d_1304_448" x="428.243" y="876.243" width="26.4853" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1304_448"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1304_448" result="shape"/>
          </filter>

          {/* FIRST */}
          <filter id="filter1_d_1304_448" x="428.243" y="1015.24" width="26.4853" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1304_448"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1304_448" result="shape"/>
          </filter>

          <filter id="filter2_d_1304_448" x="576.243" y="965.243" width="26.4853" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1304_448"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1304_448" result="shape"/>
          </filter>
          <filter id="filter3_d_1304_448" x="361.243" y="2062.24" width="26.4853" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1304_448"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1304_448" result="shape"/>
          </filter>
          <filter id="filter4_d_1304_448" x="598.243" y="2062.24" width="26.4853" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1304_448"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1304_448" result="shape"/>
          </filter>
          <filter id="filter5_d_1304_448" x="598.243" y="2186.24" width="26.4853" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1304_448"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1304_448" result="shape"/>
          </filter>
          <filter id="filter6_d_1304_448" x="380.243" y="2984.24" width="26.4853" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1304_448"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1304_448" result="shape"/>
          </filter>
          <filter id="filter7_d_1304_448" x="451.243" y="3087.24" width="26.4853" height="26.4854"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="4.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.999705 0 0 0 0 0.999705 0 0 0 0 0.858629 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1304_448"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1304_448" result="shape"/>
          </filter>
        </defs>
      </svg>

    </div>
  );
};

export default StepsLine;