import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import './CustomPhoneInput.scss';

const CustomPhoneInput = ({ label, value, name, errorMsg, onChange }) => {
  return (
    <div className="phone-input">
      <div className="phone-input__content">
        <label>{label}</label>
        <PhoneInput
          name={name}
          country={'us'}
          value={value}
          onChange={onChange}
          containerClass="custom-phone-input__container"
          inputClass="custom-phone-input"
          buttonClass="custom-phone-input__button"
          dropdownClass="custom-phone-input__dropdown"
        />
      </div>

      <span className="phone-input__info">{errorMsg}</span>
    </div>
  );
};

export default CustomPhoneInput;
