import React from 'react';
import { useSelector } from 'react-redux';

import './OrderPortrait.scss';

import SpotPortrait from '../../../components/SpotPortrait/SpotPortrait';

const OrderPortrait = () => {
  const data = useSelector((state) => state.nails.nails);
  const nails = useSelector(state => state.orderFaceNail.nails);

  return (
    <div className='order__portrait'>
      <SpotPortrait
        nails={data}
        selectedData={nails}
        isOrder={true}
        svgWidth={window.innerWidth > 500 ? window.innerWidth / 3 : window.innerWidth}
        svgHeight={window.innerWidth > 500 ? window.innerHeight - 249 : 503}
        width={window.innerWidth > 500 ? 434 : 360}
        height={window.innerWidth > 500 ? 607 : 503}
        imageX={window.innerWidth > 500 ? -2 : 5}
      />
    </div>
  )
}

export default OrderPortrait;