import React from 'react';

import './CheckButton.scss';

const CheckButton = ({ title, isActive, onClick }) => {
  return (
    <div className={isActive ? 'check-button check-button_active' : 'check-button'} onClick={onClick}>
      {title}
    </div>
  )
}

export default CheckButton;