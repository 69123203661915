import React, { useState } from 'react';
import ModalContainer from '../../../components/ModalContainer/ModalContainer';
import './EmailConfirm.scss';
import { ReactComponent as CloseIcon } from '../../../assets/close-icon.svg';
import Button from '../../../components/Button/Button';
import API from '../../../API/API';

const EmailConfirm = ({
  isShow,
  setIsShow,
  email = '',
  setModalText,
  setShowAlert,
  setEmailConfirmed,
  names
}) => {
  const [code, setCode] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [disabledConfirm, setConfirmDisabled] = useState(false);

  const handleClose = () => {
    setIsShow(false);
  };

  const handleChange = (e) => {
    setCode(e.target.value);
  };

  const resendCode = () => {
    setDisabled(true);
    API.order
      .sendCode({ email, names })
      .then(() => {})
      .catch((err) => {
        setModalText(JSON.stringify(err.response.data));
        setShowAlert(true);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  const confirmEmail = () => {
    setConfirmDisabled(true);
    API.order
      .checkCode({ email, code: Number(code) })
      .then(() => {
        setEmailConfirmed(true);
        handleClose();
      })
      .catch((err) => {
        setModalText(JSON.stringify(err.response.data));
        setShowAlert(true);
      })
      .finally(() => {
        setConfirmDisabled(false);
      });
  };

  return (
    <ModalContainer isShow={isShow}>
      <div className={'email-confirm'}>
        <div>
          <h3 className={'title'}>Email confirmation</h3>
          <div className={'close-icon'}>
            <CloseIcon onClick={handleClose} />
          </div>
        </div>

        <div>
          <div className={'info'}>
            <p className={'text'}>A confirmation code was sent to the specified email:</p>

            <p className={'text text__email'}>{email}</p>

            <p className={'text'}>Enter it below to confirm your order:</p>

            <input
              onChange={handleChange}
              type="number"
              placeholder={'000000'}
              className={'input'}
            />
          </div>

          <div>
            <p className={'description'}>Didn&apos;t receive a code in the mail?</p>
            <div className={'buttons-wrapper'}>
              <Button
                isDisabled={disabled}
                type={'info_md'}
                title={'resend code'}
                onClick={resendCode}
              />
              <p className={'description description_mobile'}>
                Didn&apos;t receive a code in the mail?
              </p>
              <Button
                isDisabled={disabledConfirm}
                type={'primary'}
                title={'confirm'}
                className={'confirm-button'}
                onClick={confirmEmail}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default EmailConfirm;
