import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setCoutriesData } from '../../store/cr7/coutriesSlice';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import GoldenCristianoFibers from './Components/GoldenCristianoFibers/GoldenCristianoFibers';
import GoldenCristianoMarble from './Components/GoldenCristianoMarble/GoldenCristianoMarble';
import GoldenCristianoArtwork from './Components/GoldenCristianoArtwork/GoldenCristianoArtwork';
import GoldenCristianoPortrait from './Components/GoldenCrisianoPortrait/GoldenCristianoPortrait';
import GoldenCristianoPartners from './Components/GoldenCristianoPartners/GoldenCristianoPartners';
import GoldenCristianoMainInfo from './Components/GoldenCristianoMainInfo/GoldenCristianoMainInfo';
import GoldenCristianoAboutNail from './Components/GoldenCristianoAboutNail/GoldenCristianoAboutNail';
import GoldenCristianoHistoryBook from './Components/GoldenCristianoHistoryBook/GoldenCristianoHistoryBook';
import GoldenCristianoParticipants from './Components/GoldenCristianoParticipants/GoldenCristianoParticipants';
import GoldenCristianoMediaPartners from './Components/GoldenCristianoMediaPartners/GoldenCristianoMediaPartners';
import GoldenCristianoOfficialPartners from './Components/GoldenCristianoOfficialPartners/GoldenCristianoOfficialPartners';
import GoldenCristianoExclusivePartners from './Components/GoldenCristianoExclusivePartners/GoldenCristianoExclusivePartners';

const GoldenCristiano = () => {
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState(1200);
  const countries = useSelector((state) => state.countriesData.countries);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, [setScreenWidth]);

  useEffect(() => {
    if (countries.length == 0) {
      axios
        .get('https://countriesnow.space/api/v0.1/countries')
        .then((res) => {
          const data = res.data.data.map((item) => {
            return { country: item.country, iso2: item.iso2 };
          });
          dispatch(setCoutriesData(data));
        })
        .catch((err) => {
          console.error('error', err);
        });
    }
  }, []);

  return (
    <>
      <Header
        aboutHashLink={'/CR7#CR7artwork'}
        isBackbutton={true}
        isShop={true}
        partnersHashLink={'/CR7#golden-cristiano-partners'}
      />
      <>
        <GoldenCristianoPortrait width={screenWidth} />
        <GoldenCristianoExclusivePartners />
        <GoldenCristianoMainInfo />
        <GoldenCristianoArtwork />
        <GoldenCristianoOfficialPartners />
        <GoldenCristianoAboutNail />
        <GoldenCristianoMarble />
        <GoldenCristianoFibers />
        <GoldenCristianoHistoryBook />
        <GoldenCristianoPartners />
        <GoldenCristianoMediaPartners />
        <GoldenCristianoParticipants />
      </>
      <Footer />
    </>
  );
};

export default GoldenCristiano;
