import React, { useEffect, useState } from 'react';

import './GoldenCristianoExclusivePartners.scss';

import Partners from "../../../../components/Partners/Partners";
import Divider from "../../../../components/Divider/Divider";
import API from "../../../../API/API";

const GoldenCristianoExclusivePartners = () => {
  const [info, setInfo] = useState({generalPartners: []});

  useEffect(() => {
    API.nailMap.partners()
      .then(res => {
        setInfo({
          generalPartners: res.data.filter(item => item.general_partner),
        })
      })
      .catch(err => {
        console.error('error getting mission data', err)
      })
  }, [])

  if (Object.values(info).every(item => item.length === 0)) {
    return <></>
  }

  return (
    <div id={'golden-cristiano-exclusive-partners'} className='exclusive-partners'>
      <Divider withMargin />
      <Partners size={'lg'} title={'General Partners'} partners={info.generalPartners} />
    </div>
  );
}

export default GoldenCristianoExclusivePartners;