import React, { useEffect } from 'react';
import ModalContainer from "../../../../../components/ModalContainer/ModalContainer";
import './BecomePartner.scss';
import { ReactComponent as CloseIcon } from "../../../../../assets/close-icon.svg";
import ReactHookFormInput from "../../../../../components/Inputs/Input/ReactHookFormInput";
import { useForm, FormProvider } from "react-hook-form";
import ReactHookFormTextarea from "../../../../../components/Inputs/Textarea/ReactHookFormTextarea";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import Button from "../../../../../components/Button/Button";

const schema = yup.object({
  company_name: yup.string().required('This field is required'),
  name: yup.string().required('This field is required'),
  phone_number: yup.string().required('This field is required'),
  email: yup.string().email("Fill in correct email").required('This field is required'),
  text: yup.string().required('This field is required'),
});

const BecomePartner = ({ isShow, setIsShow, setShowInfoModal, setInfoModalText, endPoint }) => {
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { register, handleSubmit, formState: { errors }, formState,  reset } = methods;

  const handleClose = () => {
    setIsShow(false);
  }

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ company_name: '', name: '', phone_number: '', email: '', text: '' });
    }
  }, [formState, reset]);

  const onSubmit = (data) => {
    endPoint(data)
      .then(() => {
        setInfoModalText('Your request is under consideration');
        setShowInfoModal(true);
        handleClose();
      })
      .catch(err => {
        let errorText = 'Something went wrong :(';

        if (typeof err === 'string') {
          errorText = err
        }

        if (err?.response?.data) {
          errorText = JSON.stringify(err?.response?.data)
        }

        setInfoModalText(errorText);
        setShowInfoModal(true);
      })
  }

  return (
    <ModalContainer isShow={isShow}>
      <div className={'become-partner'}>
        <div>
          <h3 className={'title'}>Become a partner</h3>
          <div className={'close-icon'}>
            <CloseIcon onClick={handleClose}/>
          </div>
        </div>

        <FormProvider {...methods} >
          <form onSubmit={handleSubmit(onSubmit)} className={'form'}>
            <ReactHookFormInput
              label='Company Name'
              marked={true}
              name='company_name'
              placeholder={'Your company name'}
              {...register("company_name")}
              errorMsg={errors?.company_name?.message}
            />
            <ReactHookFormInput
              label='Name'
              marked={true}
              name='name'
              placeholder={'Your name'}
              {...register("name")}
              errorMsg={errors?.name?.message}
            />
            <ReactHookFormInput
              label='contact phone'
              marked={true}
              name='phone_number'
              placeholder={'+4 000 000 000'}
              {...register("phone_number")}
              errorMsg={errors?.phone_number?.message}
            />
            <ReactHookFormInput
              label='contact email'
              marked={true}
              name='email'
              placeholder={'sample@sample.com'}
              {...register("email")}
              errorMsg={errors?.email?.message}
            />
            <ReactHookFormTextarea
              label='Message'
              marked={true}
              name='text'
              placeholder={'Your message'}
              errorMsg={errors?.text?.message}
            />

            <p className={'info'}>
              By clicking &quot;Send&quot; you agree with the rules of
              the service and the policy of personal data processing
            </p>

            <div className={'buttons'}>
              <Button type={'info_md'} title={'cancel'} onClick={handleClose} />
              <Button type={'primary'} title={'send'} isSubmit />
            </div>
          </form>
        </FormProvider>
      </div>
    </ModalContainer>
  );
};

export default BecomePartner;