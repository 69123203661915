import React, { useCallback, useEffect, useState } from 'react';
import './HomeProgramSteps.scss';
import Nail1Plug from '../../../../assets/home/home-nails-1.png';
import Nail2Plug from '../../../../assets/home/home-nails-2.png';
import Nail3Plug from '../../../../assets/home/home-nails-3.png';
import Nail4Plug from '../../../../assets/home/home-nails-4.png';
import Nail5Plug from '../../../../assets/home/home-nails-5.png';
import Nail6Plug from '../../../../assets/home/home-nails-6.png';
import Nail7Plug from '../../../../assets/home/home-nails-7.png';
import Smile from '../../../../assets/home/home-smile.svg';
import Button from '../../../../components/Button/Button';
import Divider from '../../../../components/Divider/Divider';
import StepsLine from './StepsLine/StepsLine';
import { useNavigate } from 'react-router-dom';
import API from '../../../../API/API';
import useMotion from '../../../../hooks/useMotion';
import { motion } from 'framer-motion';
import bottomVariant from '../../../../animations/bottomVariant';

const HomeProgramSteps = () => {
  const { controls: controls1, ref: ref1 } = useMotion();
  const { controls: controls2, ref: ref2 } = useMotion();
  const { controls: controls3, ref: ref3 } = useMotion();
  const { controls: controls4, ref: ref4 } = useMotion();
  const { controls: controls5, ref: ref5 } = useMotion();
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    title:
      'There will be just seven unique collectible gold nail art paintings for seven art guardians',
    description:
      'Seven collectible portraits will be alternately created with gold nails, on seven different subjects:',
    text2:
      'The uniqueness of the collection is that on each portrait will be encrypted messages from the author himself for the future.',
    text3: 'This mystery knows only the author himself and does not disclose to anyone.',
    pictures: [
      {
        title: 'The Name',
        text: 'Participants write their own names',
        image: Nail1Plug
      },
      {
        title: 'childs',
        text: "Participants write their children's names",
        image: Nail2Plug
      },
      {
        title: 'mother',
        text: "Participants write their children's names",
        image: Nail3Plug
      },
      {
        title: 'Father',
        text: 'Participants write the names of their fathers',
        image: Nail4Plug
      },
      {
        title: 'legendary personalities',
        text: 'Participants write the names of their idol',
        image: Nail5Plug
      },
      {
        title: 'home',
        text: 'Participants write their place of residence or birth.',
        image: Nail6Plug
      },
      {
        title: 'space',
        text: 'Participants write greetings in their languages to be sent into space.',
        image: Nail7Plug
      }
    ]
  });

  const handleClick = useCallback(() => {
    navigate('/CR7');
  }, [navigate]);

  useEffect(() => {
    API.home
      .programPortrets()
      .then((res) => {
        const data = res.data;
        data.pictures = data.pictures.sort((a, b) => a.id - b.id);
        console.log('Data => ', data);
        setInfo(data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="home-program-steps" ref={ref1}>
      <motion.div
        animate={controls1}
        initial="hidden"
        variants={bottomVariant}
        className={'step step-one'}>
        <img className={'nail-image_big'} src={info.pictures[0]?.image} alt="nails with names" />

        <div className={'step-one__content'}>
          <div className={'description'}>
            <div className={'description__title-container'}>
              <p className={'description__title'}>01</p>
              <p
                className={`description__title ${
                  info.pictures[0]?.title.length > 17 && 'description__title_sm'
                }`}>
                {info.pictures[0]?.title}
              </p>
            </div>
            <p className={'description__text'}>{info.pictures[0]?.text}</p>
          </div>

          <Button onClick={handleClick} type={'primary'} title={'Run Project'} />
        </div>
      </motion.div>

      <div ref={ref2} className={'step step-two'}>
        <motion.div
          animate={controls2}
          initial="hidden"
          variants={bottomVariant}
          className={'card'}>
          <img className={'card__image'} src={info.pictures[1]?.image} alt="children" />
          <div className={'description'}>
            <div className={'description__title-container'}>
              <p className={'description__title'}>02</p>
              <p
                className={`description__title ${
                  info.pictures[1]?.title.length > 17 && 'description__title_sm'
                }`}>
                {info.pictures[1]?.title}
              </p>
            </div>
            <p className={'description__text'}>{info.pictures[1]?.text}</p>
          </div>
        </motion.div>

        <motion.div
          animate={controls2}
          initial="hidden"
          variants={bottomVariant}
          className={'card card_third'}>
          <img className={'card__image'} src={info.pictures[2]?.image} alt="children" />
          <div className={'description'}>
            <div className={'description__title-container'}>
              <p className={'description__title'}>03</p>
              <p
                className={`description__title ${
                  info.pictures[2]?.title.length > 17 && 'description__title_sm'
                }`}>
                {info.pictures[2]?.title}
              </p>
            </div>
            <p className={'description__text'}>{info.pictures[2]?.text}</p>
          </div>
        </motion.div>
      </div>

      <div ref={ref3} className={'step step-third'}>
        <motion.div
          animate={controls3}
          initial="hidden"
          variants={bottomVariant}
          className={'card card_fourth'}>
          <img className={'card__image'} src={info.pictures[3]?.image} alt="children" />
          <div className={'description'}>
            <div className={'description__title-container'}>
              <p className={'description__title'}>04</p>
              <p
                className={`description__title ${
                  info.pictures[3]?.title.length > 17 && 'description__title_sm'
                }`}>
                {info.pictures[3]?.title}
              </p>
            </div>
            <p className={'description__text'}>{info.pictures[3]?.text}</p>
          </div>
        </motion.div>

        <motion.div
          animate={controls3}
          initial="hidden"
          variants={bottomVariant}
          className={'card card_fifth'}>
          <img className={'card__image'} src={info.pictures[4]?.image} alt="children" />
          <div className={'description'}>
            <div className={'description__title-container'}>
              <p className={'description__title description__title_margin_top'}>05</p>
              <p
                className={`description__title ${
                  info.pictures[4]?.title.length > 17 && 'description__title_sm'
                }`}>
                {info.pictures[4]?.title}
              </p>
            </div>
            <p className={'description__text'}>{info.pictures[4]?.text}</p>
          </div>
        </motion.div>
      </div>

      <div ref={ref4} className={'step step-fourth'}>
        <motion.div
          animate={controls4}
          initial="hidden"
          variants={bottomVariant}
          className={'card card_sixth'}>
          <img className={'card__image'} src={info.pictures[5]?.image} alt="children" />
          <div className={'description'}>
            <div className={'description__title-container'}>
              <p className={'description__title'}>06</p>
              <p
                className={`description__title ${
                  info.pictures[5]?.title.length > 17 && 'description__title_sm'
                }`}>
                {info.pictures[5]?.title}
              </p>
            </div>
            <p className={'description__text'}>{info.pictures[5]?.text}</p>
          </div>
        </motion.div>

        <motion.div
          animate={controls4}
          initial="hidden"
          variants={bottomVariant}
          className={'card card_seventh'}>
          <img className={'card__image'} src={info.pictures[6]?.image} alt="children" />
          <div className={'description'}>
            <div className={'description__title-container'}>
              <p className={'description__title'}>07</p>
              <p
                className={`description__title ${
                  info.pictures[6]?.title.length > 17 && 'description__title_sm'
                }`}>
                {info.pictures[6]?.title}
              </p>
            </div>
            <p className={'description__text'}>{info.pictures[6]?.text}</p>
          </div>
        </motion.div>
      </div>

      <StepsLine />

      <div ref={ref5} className={'smile'}>
        <motion.div
          animate={controls5}
          initial="hidden"
          variants={bottomVariant}
          className={'smile__content'}>
          <p className={'smile__text'}>{info?.text2}</p>
          <p className={'smile__text smile__text_end'}>{info?.text3}</p>
        </motion.div>
        <div className={'smile__img'}>
          <motion.img
            animate={controls5}
            initial="hidden"
            variants={bottomVariant}
            src={Smile}
            alt="smile"
          />
        </div>
      </div>

      <div className={'divider-container'}>
        <Divider withMargin />
      </div>
    </div>
  );
};

export default HomeProgramSteps;
