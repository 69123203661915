import React from 'react';
import './NotFoundPage.scss';

const NotFoundPage = () => {
  return (
    <div className={'not-found'}>
      <p className={'not-found__text'}>404</p>
    </div>
  );
};

export default NotFoundPage;
