import React, { useEffect, useState } from 'react';
import './HomeProgram.scss';
import Divider from '../../../../components/Divider/Divider';
import { ReactComponent as PlayerNumber } from '../../../../assets/home/home-number.svg';
import API from '../../../../API/API';
import DomPurify from '../../../../components/sanitizeHtml/DomPurify';
import { motion } from 'framer-motion';
import useMotion from '../../../../hooks/useMotion';
import bottomVariant from '../../../../animations/bottomVariant';
import WavyText from '../../../../components/WavyText/WavyText';
import imageOpacityVariant from '../../../../animations/imageOpacityVariant';

const HomeProgram = () => {
  const { controls, ref } = useMotion();
  const { controls: controls1, ref: ref1 } = useMotion();
  const { controls: controls3, ref: ref3 } = useMotion();
  const [info, setInfo] = useState({
    title: 'The program',
    description:
      "Humanity's Name Legacy Program, which is designed to perpetuate the name of every member of the planet earth.",
    image: null,
    datas: [
      {
        title: 'The Guardians',
        text1:
          'We hope to preserve the names of our contemporaries who have left a significant mark on our era.',
        text2:
          'These are the people who have influenced life, the worldview, technology, and most importantly, those who see the value in it and walk with us through time into the future.\r\nThe Guardian is the global status of the recipient of the heritage portrait with names.',
        image: null
      },
      {
        title: 'The participant',
        text1:
          'is someone who wants to leave his or her name on the gold nails with which the collectible paintings will be created.\r\n\nEach participant is awarded an electronic and hard copy heritage certificate.',
        text2: '*Persons of legal age are eligible to participate.',
        image: null
      },
      {
        title:
          'There will be just seven unique collectible gold nail art paintings for seven art guardians',
        text1:
          'Seven collectible portraits will be alternately created with gold nails, on seven different subjects:'
      }
    ]
  });

  useEffect(() => {
    API.home
      .program()
      .then((res) => {
        setInfo(res.data);
      })
      .catch((err) => {
        console.error('error getting mission data', err);
      });
  }, []);

  return (
    <div id={'home-program'} ref={ref} className="home-program">
      <motion.h2
        animate={controls}
        initial="hidden"
        variants={bottomVariant}
        className={'text-center'}>
        {info?.title}
      </motion.h2>
      <motion.div
        animate={controls}
        initial="hidden"
        variants={bottomVariant}
        className="title-description">
        <div className={'text text-center text_sm'}>
          <DomPurify dirtyText={info?.description} />
        </div>
      </motion.div>

      <div className="primary">
        <motion.h3 animate={controls} initial="hidden" variants={bottomVariant} className={'title'}>
          {info?.datas[0]?.title}
        </motion.h3>

        <motion.img
          animate={controls}
          initial="hidden"
          variants={imageOpacityVariant}
          className="image_primary_mobile"
          src={info?.image}
          alt="ronaldo"
        />

        <motion.p
          animate={controls}
          initial="hidden"
          variants={bottomVariant}
          className="text text_sm text_margin">
          {info.datas[0]?.text1}
        </motion.p>
        <motion.p
          animate={controls}
          initial="hidden"
          variants={bottomVariant}
          className="text text_md text_margin_left">
          {info.datas[0]?.text2}
        </motion.p>

        <motion.img
          animate={controls}
          initial="hidden"
          variants={imageOpacityVariant}
          className="image_primary"
          src={info?.image}
          alt="ronaldo"
        />
      </div>

      <div className="secondary" ref={ref1}>
        {info.datas[1]?.image && (
          <motion.img
            animate={controls1}
            initial="hidden"
            variants={imageOpacityVariant}
            className="image_secondary"
            src={info.datas[1]?.image}
            alt="hands love"
          />
        )}
        <motion.div
          animate={controls1}
          initial="hidden"
          variants={bottomVariant}
          className="secondary-content">
          <h3 className={'title'}>{info.datas[1]?.title}</h3>
          {info.datas[1]?.image && (
            <motion.img
              animate={controls1}
              initial="hidden"
              variants={imageOpacityVariant}
              className="image_secondary_mobile"
              src={info.datas[1]?.image}
              alt="hands love"
            />
          )}
          <p className="text text_sm text_margin text_margin_secondary_mobile">
            {info.datas[1]?.text1}
          </p>
          <div className="text text_gold text_margin_left text_gold_width">
            <DomPurify dirtyText={info.datas[1]?.text2} />
          </div>
        </motion.div>
      </div>

      <div className="divider-container">
        <Divider className={'divider'} />
      </div>

      <WavyText
        text={info.datas[2]?.title}
        containerClassName={'details'}
        textClassName="details-text"
        duration={0.1}
      />

      <div className="divider-container">
        <Divider withMargin />
      </div>

      <div ref={ref3} className="player-number">
        <PlayerNumber className="player-number-image" />
        <motion.div
          animate={controls3}
          initial="hidden"
          variants={imageOpacityVariant}
          className="player-number-content">
          <motion.p
            animate={controls3}
            initial="hidden"
            variants={bottomVariant}
            className="text player-number-text_width text-center">
            {info.datas[2]?.text1}
          </motion.p>
        </motion.div>
      </div>
    </div>
  );
};

export default HomeProgram;
