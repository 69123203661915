import { useEffect, useRef, useState } from 'react';

const useDrawLine = (startAnimationValue) => {
  const container = useRef(null);
  const line = useRef(null);
  const [drawPercent, setDrawPercent] = useState(0);

  useEffect(() => {
    const length = line?.current?.getTotalLength();

    const scrollFunction = () => {
      const containerTop = container?.current.getBoundingClientRect().top;
      const containerHeight = container?.current?.clientHeight;

      if (containerTop < startAnimationValue) {
        const finalContainerTop = Math.abs(containerTop - startAnimationValue);
        const finalContainerHeight = containerHeight - startAnimationValue;

        const scrollPercent = (finalContainerTop * 100) / finalContainerHeight;
        setDrawPercent(scrollPercent);

        let draw = (length * scrollPercent) / 100;

        if (draw >= length) {
          draw = length;
        }

        // Reverse the drawing (when scrolling upwards)
        line.current.style.strokeDashoffset = length - draw;
      } else {
        // hide the line fully when you don't get to it
        line.current.style.strokeDashoffset = length;
      }
    };

    if (line?.current && container?.current) {
      // The start position of the drawing
      line.current.style.strokeDasharray = length;

      // Hide the triangle by offsetting dash. Remove this line to show the triangle before scroll draw
      line.current.style.strokeDashoffset = length;

      window.addEventListener('scroll', scrollFunction);
    }

    return () => window.removeEventListener('scroll', scrollFunction);
  }, [line, container]);

  return { line, container, drawPercent, setDrawPercent };
};

export default useDrawLine;
