const bottomVariant = {
  hidden: {
    opacity: 0,
    y: '100px',
    z: "40px",
    transition: {
      duration: 1,
    }
  },
  visible: {
    opacity: 1,
    y: 0,
    z: "40px",
    transition: {
      duration: 1,
    }
  },
  exit: { opacity: 0 }
}

export default bottomVariant;