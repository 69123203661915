import React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import './FiltersBottomSheet.scss';
import { ReactComponent as CloseIcon } from '../../../../../../assets/close-icon.svg';
import { ReactComponent as SearchIcon } from '../../../../../../assets/SearchIconMobile.svg';
import CheckButton from '../../../../../../components/CheckButton/CheckButton';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../../../../../store/cr7/nailsSlice';
import Switch from 'react-switch';

const FiltersBottomSheet = ({ open, setOpen, onChange }) => {
  const filtersState = useSelector((state) => state.nails.filters);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.nails.nails);
  const available =
    data.length -
    data.filter((item) => item.bought || item.fake_bought || item.offline_purchase).length;

  const handleClose = () => {
    setOpen(false);
  };

  const handlePersonalPurchaseClick = () => {
    dispatch(
      setFilters({
        personalPurchase: !filtersState.personalPurchase,
        corporatePurchase: false,
        onlyAvailable: filtersState.onlyAvailable
      })
    );
  };

  const handleCorporatePurchaseClick = () => {
    dispatch(
      setFilters({
        personalPurchase: false,
        corporatePurchase: !filtersState.corporatePurchase,
        onlyAvailable: filtersState.onlyAvailable
      })
    );
  };

  const handleSwitch = () => {
    dispatch(
      setFilters({
        personalPurchase: filtersState.personalPurchase,
        corporatePurchase: filtersState.corporatePurchase,
        onlyAvailable: !filtersState.onlyAvailable
      })
    );
  };

  return (
    <BottomSheet open={open} onDismiss={handleClose} className={'filters-bottom-sheet'}>
      <div className={'filters-bottom-sheet__content'}>
        <div className={'filters-bottom-sheet__header'}>
          <p className={'filters-bottom-sheet__title'}>Search & Filter</p>
          <CloseIcon onClick={handleClose} />
        </div>

        <div className={'filters-bottom-sheet__search'}>
          <SearchIcon />
          <input
            maxLength={4}
            className={'filters-bottom-sheet__search-input'}
            type="number"
            onChange={(e) => onChange(e)}
            placeholder={'search nail by number'}
          />
        </div>

        <div className={'filters-bottom-sheet__divider'} />

        <p className={'filters-bottom-sheet__content-title'}>Show me available for:</p>

        <div className={'filters-bottom-sheet__checkboxes'}>
          <CheckButton
            title="personal purchase"
            isActive={filtersState.personalPurchase}
            onClick={handlePersonalPurchaseClick}
          />
          <CheckButton
            title="corporate purchase"
            isActive={filtersState.corporatePurchase}
            onClick={handleCorporatePurchaseClick}
          />
        </div>

        <div className="filters-bottom-sheet__switcher">
          <Switch
            onChange={handleSwitch}
            checked={filtersState.onlyAvailable}
            className="react-switch"
            onColor="#48B913"
            offColor="#C6B689"
            onHandleColor="#000000"
            offHandleColor="#000000"
            checkedIcon={false}
            uncheckedIcon={false}
            handleDiameter={17}
            height={21}
            width={43}
          />

          <div className={'filters-bottom-sheet__switcher-content'}>
            <p
              className={`${
                filtersState.onlyAvailable
                  ? 'filters-bottom-sheet__switcher-content-text_active'
                  : ''
              } filters-bottom-sheet__switcher-content-text`}>
              only available for purchase
            </p>
            <p
              className={
                'filters-bottom-sheet__switcher-content-text filters-bottom-sheet__switcher-content-text_nails'
              }>
              ({available} nails)
            </p>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

export default FiltersBottomSheet;
