import React, { useEffect, useState } from 'react';
import './Contacts.scss';
import Header from "../../components/Header/Header";
import Divider from "../../components/Divider/Divider";
import API from "../../API/API";

const Contacts = () => {
  const [info, setInfo] = useState({
    "telegram": "@constellationgram",
    "email": "office@c-art.gallery",
    "phone_number": "+996 777 07 07 07",
    "address": "Bishkek. Aitmatov ave 17",
    "instagram": '',
    "twitter": '',
    "facebook": ''
  })

  useEffect(() => {
    API.common.contacts()
      .then(res => {
        setInfo(res.data);
      })
      .catch(err => {
        console.error('error getting footer data', err)
      })
  }, [])

  return (
    <>
      <Header isFullLogo/>
      <div className={'contacts'}>
        <span></span>

        <div className={'title-container'}>
          <p className={'title'}>Become part of the world&apos;s heritage</p>
          <Divider withMargin/>
        </div>

        <div className={'contacts-footer'}>
          <div className={'links'}>
            <a href={`mailto:${info.email}`} className={'links__text'} aria-label={info.email}>{info.email}</a>

            <p className={'links__text links__text_grey'}>{info.address}</p>

            <div className={'social'}>
              <a className={'links__text links__text_grey'} target={'_blank'} rel={'noreferrer'}
                 href={info.twitter} aria-label={info.twitter}>tw</a>
              <a className={'links__text links__text_grey'} target={'_blank'} rel={'noreferrer'}
                 href={info.instagram} aria-label={info.instagram}>in</a>
              <a className={'links__text links__text_grey'} target={'_blank'} rel={'noreferrer'}
                 href={info.facebook} aria-label={info.facebook}>fb</a>
            </div>

            <a target={'_blank'} rel={'noreferrer'} href={`https://wa.me/${info.phone_number}`}
               className={'links__text'} aria-label={info.phone_number}>
              {info.phone_number}
            </a>
          </div>

          <div className='copyright'>©2022 c-art.gallery</div>
        </div>
      </div>
    </>
  );
};

export default Contacts;