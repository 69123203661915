import React, { useEffect, useState } from 'react';

import './GoldenCristianoOfficialPartners.scss';

import Partners from '../../../../components/Partners/Partners';
import Divider from '../../../../components/Divider/Divider';
import API from '../../../../API/API';

const GoldenCristianoOfficialPartners = () => {
  const [info, setInfo] = useState({ officialPartners: [] });

  useEffect(() => {
    API.nailMap
      .partners()
      .then((res) => {
        setInfo({
          officialPartners: res.data.filter((item) => item.official_partner)
        });
      })
      .catch((err) => {
        console.error('error getting official partner data', err);
      });
  }, []);

  if (Object.values(info).every((item) => item.length === 0)) {
    return <></>;
  }

  return (
    <div id={'official-partners'} className="official-partners">
      <Divider />
      <Partners size={'sm'} title={'official Partners'} partners={info.officialPartners} />
      <Divider />
    </div>
  );
};

export default GoldenCristianoOfficialPartners;
