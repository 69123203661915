import React, { useEffect, useState } from 'react';
import './GoldenCristianoHistoryBook.scss';
import historyBookDefault from '../../../../assets/golden-cristiano/history-book-default.png';
import API from "../../../../API/API";
import Button from '../../../../components/Button/Button';
import Divider from '../../../../components/Divider/Divider';
import DomPurify from "../../../../components/sanitizeHtml/DomPurify";
import WavyText from "../../../../components/WavyText/WavyText";
import { motion } from 'framer-motion';
import useMotion from "../../../../hooks/useMotion";
import bottomVariant from "../../../../animations/bottomVariant";
import imageOpacityVariant from "../../../../animations/imageOpacityVariant";

const GoldenCristianoHistoryBook = () => {
  const { controls, ref } = useMotion();
  const [info, setInfo] = useState({
    title: 'HERITAGE PASSPORT',
    description: 'An official document that is legal evidence of the authenticity of the materials performed and each name of the program participant.',
    image: historyBookDefault,
    text2: 'An information document that contains all of the names and their countries of each 7777 program participant.',
    sub_description: 'CERTIFICATE OF COMPLIANCE'
  });

  useEffect(() => {
    API.nailMap.historyBook()
      .then(res => {
        setInfo(res.data)
      })
      .catch(err => {
        console.error('error getting history book data', err)
      })
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div ref={ref} className='history-book'>
      <WavyText text={info.title} textClassName={'text-center h3'} isLetterSplit={window.innerWidth > 768}/>
      <div className={'history-book__title-description'}>
        <DomPurify dirtyText={info.sub_description}/>
      </div>
      <div className='history-book__content'>
        <div>
          <motion.div animate={controls} initial="hidden" variants={bottomVariant}
                    className={'text text_sm history-book__description'}>
            <DomPurify dirtyText={info.description}/>
          </motion.div>
          <motion.div animate={controls} initial="hidden" variants={bottomVariant}
                    className={'text text_sm history-book__description history-book__description_sm'}>
            <DomPurify dirtyText={info.text2}/>
          </motion.div>
        </div>

        <motion.img animate={controls} initial="hidden" variants={imageOpacityVariant} className='history-book__image'
                    src={info.image} alt='info.title'/>
      </div>

      <div className='history-book__action'>
        <WavyText textClassName={"h2 text-center"} text={'become a part of heritage'}
                  isLetterSplit={window.innerWidth > 1200}/>
        <Button type='primary' title='Run project' onClick={handleClick} />
      </div>

      <Divider/>
    </div>
  )
}

export default GoldenCristianoHistoryBook;