import React, { useEffect, useState } from 'react';

import './List.scss';
import { useDispatch, useSelector } from 'react-redux';
import { orderSecondStep } from '../../../../../store/cr7/orderFaceNailSlice';
import { useFormContext } from 'react-hook-form';
import addZeroBeforeNumber from '../../../../../utils/addZeroBeforeNumber';

const List = ({ onChange, minimumNailPrice, isBackgroundNails }) => {
  const [placeholder, setPlaceholder] = useState('enter text');
  const nails = useSelector((state) => state.orderFaceNail.nails);
  const nailsIsTouched = useSelector((state) => state.orderFaceNail.nailsIsTouched);
  const dispatch = useDispatch();
  const { watch } = useFormContext();

  const handleFocus = () => {
    setPlaceholder('UP to 10 symbols');
  };

  const handleBlur = () => {
    setPlaceholder('enter text');
  };

  useEffect(() => {
    if (nailsIsTouched) return;

    const subscription = watch((value) => {
      const { name } = value;

      const nailsCopy = nails.map((item) => item);
      const updatedNails = nailsCopy.map((item) => ({ ...item, name: name }));

      dispatch(orderSecondStep({ nails: updatedNails }));
    });

    return () => subscription.unsubscribe();
  }, [watch, nailsIsTouched]);

  return (
    <div className="list">
      <div className="list__header">
        <p>Selected nails:</p>
        <p>text on the nail</p>
      </div>
      {nails.length >= 1
        ? nails.map((item) => {
            const nailNumber = `#${addZeroBeforeNumber(item.id)}`;
            return (
              <div key={item.id}>
                <div className="list__item list__item_tablet">
                  <div>{nailNumber}</div>
                  <input
                    type="text"
                    value={item.name.substring(0, 10)}
                    placeholder={placeholder}
                    maxLength={10}
                    disabled={isBackgroundNails}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={(e) => onChange(e, item.id, 'name')}
                  />
                  <input
                    type="number"
                    value={item.price}
                    placeholder={minimumNailPrice.toString()}
                    maxLength={6}
                    min={minimumNailPrice}
                    onChange={(e) => onChange(e, item.id, 'price')}
                  />
                </div>

                <div className="list__item list__item_mobile">
                  <div className={'list__item-content_mobile'}>
                    <p className={'list__item-text_mobile'}>nail number:</p>
                    <p className={'list__item-text_mobile'}>{nailNumber}</p>
                  </div>

                  <div className={'list__item-content_mobile'}>
                    <p className={'list__item-text_mobile'}>Text on the nail</p>
                    <input
                      type="text"
                      value={item.name.substring(0, 10)}
                      placeholder={placeholder}
                      maxLength={10}
                      disabled={isBackgroundNails}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={(e) => onChange(e, item.id, 'name')}
                    />
                  </div>

                  <div className={'list__item-content_mobile'}>
                    <p className={'list__item-text_mobile'}>price of the nail</p>
                    <input
                      type="number"
                      value={item.price}
                      placeholder={minimumNailPrice.toString()}
                      maxLength={6}
                      min={minimumNailPrice}
                      onChange={(e) => onChange(e, item.id, 'price')}
                    />
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default List;
