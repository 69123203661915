import React, { useEffect, useState } from 'react';

import './GoldenCristianoPartners.scss';

import Partners from "../../../../components/Partners/Partners";
import Divider from "../../../../components/Divider/Divider";
import Button from "../../../../components/Button/Button";
import API from "../../../../API/API";
import BecomePartner from "../../../Home/Components/Modals/BecomePartner/BecomePartner";
import ModalAlert from "../../../../components/ModalAlert/ModalAlert";

const GoldenCristianoPartners = () => {
  const [info, setInfo] = useState({partners: []});

  const [showPartnersModal, setShowPartnersModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalText, setInfoModalText] = useState(false);

  const openPartnersModal = () => {
    setShowPartnersModal(true)
  }

  const handleInfoModalClose = () => {
    setShowInfoModal(false)
    setInfoModalText('')
  }

  useEffect(() => {
    API.nailMap.partners()
      .then(res => {
        setInfo({
          partners: res.data.filter(item => item.partner),
        })
      })
      .catch(err => {
        console.error('error getting mission data', err)
      })
  }, [])

  if (Object.values(info).every(item => item.length === 0)) {
    return <></>
  }

  return (
    <div id={'golden-cristiano-partners'} className='golden-cristiano-partners'>
      <Partners size={'sm'} title={'Partners'} partners={info.partners} />
      <Button onClick={openPartnersModal} type={'primary'} className={'button'} title={'Become a partner'} />
      <Divider withMargin />

      <BecomePartner isShow={showPartnersModal} setIsShow={setShowPartnersModal} endPoint={API.nailMap.becomePartner}
                     setShowInfoModal={setShowInfoModal} setInfoModalText={setInfoModalText} />
      <ModalAlert isShow={showInfoModal} text={infoModalText} handleClose={handleInfoModalClose}/>
    </div>
  );
}

export default GoldenCristianoPartners;