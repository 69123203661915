import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { orderSecondStep } from '../../../store/cr7/orderFaceNailSlice';
import './OrderList.scss';
import classNames from 'classnames';
import Button from '../../Button/Button';
import { ReactComponent as CloseIcon } from '../../../assets/close-icon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/trash-icon.svg';
import { Scrollbars } from 'react-custom-scrollbars';

const OrderList = ({ isOrderList, orderList, onClose, onRemoveItem }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDisabled, setDisabled] = useState();
  const [nailsLimitText, setNailsLimitText] = useState();
  const totalSum = orderList.reduce((a, { price }) => a + parseInt(price), 0);
  const classes = classNames({
    'order-list': true,
    'order-list_fade-in': isOrderList,
    'order-list_fade-out': !isOrderList
  });

  const handlePurchase = () => {
    const orderData = orderList.map((item) => {
      return {
        id: item.number,
        x: item.x,
        y: item.y,
        price: item.price,
        background: item.background,
        name: ''
      };
    });

    dispatch(orderSecondStep({ nails: orderData }));
    navigate('/order');
  };

  const addZeroBeforeNumber = (number) => {
    const numberString = number.toString();
    return numberString.padStart(5, '0');
  };

  useEffect(() => {
    if (orderList.some((item) => item.background)) {
      setNailsLimitText('you must select minimum 7 nails');
      setDisabled(orderList.length >= 7 ? false : true);
    } else if (orderList.some((item) => !item.background)) {
      setNailsLimitText('you must select maximum 7 nails');
      setDisabled(orderList.length <= 7 ? false : true);
    } else {
      setNailsLimitText('');
      setDisabled(true);
    }
  }, [orderList]);

  return (
    <div className={classes}>
      <div className="order-list__header">
        <div className="order-list__action-block">
          <span>your collection:</span>
          <CloseIcon onClick={onClose} />
        </div>
        <div className="order-list__header-text">
          <span>Selected nails:</span>
          <span>Price:</span>
        </div>
      </div>
      <Scrollbars
        autoHeight
        style={{ height: '60vh' }}
        autoHeightMin={'60vh'}
        className="order-list__content">
        {orderList.map((orderItem, index) => {
          return (
            <div key={index} className="order-list__item">
              <span>#{addZeroBeforeNumber(orderItem.number)}</span>
              <div onClick={() => onRemoveItem(orderItem.number)}>
                <TrashIcon />
                <span>remove</span>
              </div>
              <span>${orderItem.price}</span>
            </div>
          );
        })}
        <div className="order-list__total-block">
          <div className="order-list__total-block__item">
            <span>nails:</span>
            <span>{orderList.length}</span>
          </div>
          <div className="order-list__total-block__item">
            <span>summary:</span>
            <span>{`$${totalSum}`}</span>
          </div>
        </div>
        <span className="order-list__info">{nailsLimitText}</span>
        <Button
          title="purchase now"
          type="primary"
          isDisabled={isDisabled}
          onClick={handlePurchase}
          style={{ width: '100%' }}
        />
      </Scrollbars>
    </div>
  );
};

export default OrderList;
