import React, { useEffect, useState } from 'react';

import './GoldenCristianoFibers.scss';

import API from '../../../../API/API';
import DomPurify from '../../../../components/sanitizeHtml/DomPurify';

import fibersMainImage from '../../../../assets/golden-cristiano/fibers-main-image.png';
import fibersDetailImage from '../../../../assets/golden-cristiano/fibers-detail-image.png';
import { motion } from 'framer-motion';
import useMotion from '../../../../hooks/useMotion';
import bottomVariant from '../../../../animations/bottomVariant';

const GoldenCristianoFibers = () => {
  const { controls, ref } = useMotion();
  const [info, setInfo] = useState({
    title: 'The FIBERS',
    description:
      'As for the thread, we will use super durable Nike flyknit - nano fiber, which is used on CR7 shoes.',
    image: fibersMainImage,
    sub_text:
      'THE PROPERTIES OF NANO-FIBERS ARE: \n1) Durable. The service life is practically unlimited. \n2) Not subject to external physical influences. \n3) Strength of nano-fibers exceeds the strength of steel by 10 times. \n4) Nano-fibers are used In the field of astronautics at NASA',
    image2: fibersDetailImage
  });

  useEffect(() => {
    API.nailMap
      .mainPageInfo()
      .then((res) => {
        setInfo(res.data.about_threads);
      })
      .catch((err) => {
        console.error('error getting fibers data', err);
      });
  }, []);

  return (
    <div ref={ref} className="fibers">
      <div className="fibers__content">
        <motion.div
          animate={controls}
          initial="hidden"
          variants={bottomVariant}
          className="fibers__right-block">
          <img className="fibers__main-image" src={info?.image} alt="nails" />
        </motion.div>

        <div className="fibers__left-block">
          <h3 className="text-center">{info?.title}</h3>
          <div className={'text text_sm fibers__text'}>
            <DomPurify dirtyText={info?.description} />
          </div>
          <div className="fibers__detail">
            <div className={'text text_sm fibers__sub_text'}>
              <DomPurify dirtyText={info?.sub_text} />
            </div>
          </div>
          <div className="fibers__overflow"></div>
          <div className="fibers__central-block fibers__central-block_desktop">
            <img className="fibers__image" src={info?.image2} alt="nail" />
          </div>
        </div>

        <div className="fibers__content_mobile">
          <h3 className="text-center">{info?.title}</h3>
          <img className="fibers__main-image_mobile" src={info?.image} alt="nails" />
          <img className="fibers__cristiano-image_mobile" src={info?.image2} alt="nail" />

          <div className={'fibers__text-container_mobile'}>
            <div className="fibers__overflow_mobile"></div>
            <div className={'text text_sm fibers__text'}>
              <DomPurify dirtyText={info?.description} />
            </div>
            <div className="fibers__detail">
              <div className={'text text_sm fibers__sub_text'}>
                <DomPurify dirtyText={info?.sub_text} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoldenCristianoFibers;
