import React, { useState, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import ExclusivePartner from '../../../../components/ExclusivePartner/ExclusivePartner';
import Button from '../../../../components/Button/Button';
import Divider from '../../../../components/Divider/Divider';
import PaymentModal from '../../../../components/PaymentModal/PaymentModal';
import './HomeMain.scss';
import useMotion from '../../../../hooks/useMotion';
import { motion } from 'framer-motion';
import Ronaldo from '../../../../assets/hero-image.webp';

const opacityVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } }
};

const HomeMain = ({ scrollY }) => {
  const { controls, ref } = useMotion();
  const navigate = useNavigate();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentModalData] = useState();

  const handleClick = useCallback(() => {
    navigate('/CR7');
  }, [navigate]);

  return (
    <div className="home-main" ref={ref}>
      <PaymentModal
        isShow={showPaymentModal}
        data={paymentModalData}
        handleClose={() => setShowPaymentModal(false)}
      />
      <span
        className={`home__title home__main-title ${
          scrollY > 0 ? 'home__main-title_scroll-top' : 'home__main-title_scroll-down'
        }`}>
        CONSTELLATION
      </span>

      <motion.div
        animate={controls}
        initial="hidden"
        variants={opacityVariant}
        className="home__overlay">
        {/*<Overlay/>*/}
        <img src={Ronaldo} alt="Ronaldo" />
      </motion.div>

      <ExclusivePartner hasBorder />

      <span className="home__title home__main-subtitle">#golden Ronaldo</span>

      <Button onClick={handleClick} type={'primary'} title="Run project" />

      <Divider withMargin />
    </div>
  );
};

export default HomeMain;
