import React, { useEffect, useState } from 'react';
import './OrderThirdStep.scss';
import ReCAPTCHA from 'react-google-recaptcha';

import ReactHookFormInput from '../../../components/Inputs/Input/ReactHookFormInput';
import { useFormContext } from 'react-hook-form';
import axios from 'axios';
import ReactHookFormSelect from '../../../components/Inputs/Select/ReactHookFormSelect';
import API from '../../../API/API';

const OrderThirdStep = ({ recaptchaRef }) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue
  } = useFormContext();
  const [allInfo, setAllInfo] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    axios;
    API.orderThirdStep
      .getCountry()
      .then((res) => {
        setAllInfo(res.data.data);
        setCountries(res.data.data.map((item) => item.country));
      })
      .catch((err) => {
        console.error('error', err);
      });
  }, []);

  // if country changed than reset city field value
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'country') {
        const newCities = allInfo.reduce((acc, country) => {
          if (country.country === value.country) {
            acc = country.cities;
          }
          return acc;
        }, []);

        setCities(newCities);

        setValue('city', '');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, allInfo]);

  return (
    <>
      <div className="third-step">
        <div className="third-step__content">
          <div className="third-step_header">
            <h2 className="text-gold">step 3.</h2>
            <h4>delivery information</h4>
            <p className={'text text_sm'}>
              A certificate confirming participation in the Golden Cristiano project will be
              delivered to the specified address
            </p>
          </div>

          <div className="third-step__form">
            <ReactHookFormSelect
              label="Your country"
              marked={true}
              name="country"
              placeholder={'Your country'}
              data={countries}
              {...register('country')}
              errorMsg={errors?.country?.message}
            />
            <ReactHookFormSelect
              label="Your city/town"
              marked={true}
              name="city"
              placeholder={'Your city/town'}
              data={cities}
              {...register('city')}
              errorMsg={errors?.city?.message}
            />
            <ReactHookFormInput
              label="province"
              name="province"
              placeholder={'Your province'}
              warningMsg={
                'Please state your real name corresponding to your identity documents in Latin letters'
              }
              {...register('province')}
              errorMsg={errors?.province?.message}
            />
            <ReactHookFormInput
              label="zip code"
              marked={true}
              name="postcode"
              placeholder={'MY ZIP CODE'}
              {...register('postcode')}
              errorMsg={errors?.postcode?.message}
            />
            <ReactHookFormInput
              label="Address line"
              marked={true}
              name="address"
              placeholder={'My address line 1'}
              {...register('address')}
              errorMsg={errors?.address?.message}
            />
            <ReactHookFormInput
              label="house / building"
              marked={true}
              name="house_building"
              placeholder={'Number of house'}
              {...register('house_building')}
              errorMsg={errors?.house_building?.message}
            />
            <ReactHookFormInput
              label="room / apartments"
              name="room_office"
              placeholder={'Number of house'}
              {...register('room_office')}
              errorMsg={errors?.room_office?.message}
            />

            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_SITE_KEY}
              size={'invisible'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderThirdStep;
