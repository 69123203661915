import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './OrderSecondStep.scss';

import { orderSecondStep, setIsNailsTouched } from '../../../store/cr7/orderFaceNailSlice';
import List from './Components/List/List';

const OrderSecondStep = ({ minimumNailPrice }) => {
  const dispatch = useDispatch();
  const nails = useSelector((state) => state.orderFaceNail.nails);

  const handleChange = (e, itemId, fieldName = 'name') => {
    let value = e.target.value;

    const updatedStoreData = JSON.parse(JSON.stringify(nails)).map((item) => {
      if (item.id === itemId) {
        item[fieldName] = value;
      }
      return item;
    }, []);

    dispatch(orderSecondStep({ nails: updatedStoreData }));

    // set nailsIsTouched to true => to prevent nails name update on name change at first step
    dispatch(setIsNailsTouched({ nailsIsTouched: true }));
  };

  return (
    <div className="first-step">
      <div className="first-step__form">
        <div className="first-step_header">
          <h2 className="text-gold">step 2.</h2>
          <h4>Set up your own heritage</h4>
          <p className={'text text_sm first-step__sub_text'}>
            Attention! The text must be up to 10 characters long and not contain any foul language
            or words/phrases that can offend anyone!
          </p>
        </div>

        <List minimumNailPrice={minimumNailPrice} onChange={handleChange} />
      </div>
    </div>
  );
};

export default OrderSecondStep;
