import React, { useState, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import PuffLoader from 'react-spinners/PuffLoader';
import { getCode } from 'country-list';
import { useSelector } from 'react-redux';

import './NailInfo.scss';

import nail from '../../../../../../assets/nail.webp';
import { ReactComponent as CloseIcon } from '../../../../../../assets/close-icon.svg';

import Button from '../../../../../../components/Button/Button';

const addZeroBeforeNumber = (number) => {
  const numberString = number.toString();
  return numberString.padStart(5, '0');
};

const renderAvailableNailInfo = (
  selectedData,
  price,
  priceLimit,
  handlePriceChange,
  isPriceError,
  handleClick,
  handleShowKeyboard,
  handleBlur
) => {
  return (
    <>
      <span className="nail-info__title">{addZeroBeforeNumber(selectedData.number)}</span>
      <span className="nail-info__status-available">available for purchase</span>

      <div className="nail-info__nail-icon">
        <img src={nail} alt="nail" />
      </div>
      <div className="nail-info__price-block">
        <span className="nail-info__description text-gold">enter your price: </span>
        <div className="nail-info__price-input-block">
          <input
            type="number"
            value={price}
            onChange={handlePriceChange}
            placeholder={`from $${priceLimit}`}
            onFocus={handleShowKeyboard}
            onBlur={handleBlur}
          />
          {isPriceError && (
            <span className="nail-info__status">
              price should be equal or greater than {priceLimit}
            </span>
          )}
          {isPriceError && (
            <>
              <div className="nail-info__price-block-error"></div>
              <div className="nail-info__price-block-error"></div>
            </>
          )}
        </div>
      </div>
      <div className="nail-info__buy-btn">
        <Button title="add to list" type="primary-grand" onClick={handleClick} />
      </div>
      {selectedData.background && (
        <span className="nail-info__status">you must select minimum 7 nails</span>
      )}
    </>
  );
};

const renderPurchasedNailInfo = (selectedData, handleCloseNailInfo) => {
  const countries = useSelector((state) => state.countriesData.countries);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState(null);

  const coutryName = selectedData.country ? selectedData.country.split(' /')[0] : '';

  const override = {
    display: 'block',
    alignSelf: 'center',
    borderColor: '#C6B689'
  };

  const getCountryCode = (countryName) => {
    return countries.find((item) => item.country == countryName).iso2;
  };

  useEffect(() => {
    setLoading(true);
    if (selectedData.country) setLoading(false);
    if (!selectedData.country) setTimeout(() => setLoading(false), 5000);
  }, [selectedData]);

  useEffect(() => {
    let nameResult;

    setName(null);

    if (!selectedData.country) return setName('Company');

    if (selectedData.nails)
      nameResult = selectedData.nails.find((item) => item.number == selectedData.number).text;
    if (nameResult) {
      setName(nameResult);
    } else if (!nameResult && selectedData.owner) {
      setName(selectedData.owner);
    } else {
      setName('Company');
    }
  }, [selectedData]);

  return (
    <>
      <div className="nail-info__reserved-item">
        <span className="nail-info__title">{addZeroBeforeNumber(selectedData.number)}</span>
        <span className="nail-info__label">purchased</span>
      </div>
      {name && (
        <div className="nail-info__reserved-item">
          <span className="nail-info__label">Text on nail:</span>
          <span className="nail-info__value">{name.toUpperCase()}</span>
        </div>
      )}
      {selectedData.country && (
        <div className="nail-info__reserved-item">
          <span className="nail-info__label">Country:</span>
          <span className="nail-info__value">
            <ReactCountryFlag
              countryCode={getCode(coutryName) ? getCode(coutryName) : getCountryCode(coutryName)}
              svg
              alt={getCode(coutryName)}
            />
            {selectedData.country}
          </span>
        </div>
      )}
      {selectedData.contacts && (
        <div className="nail-info__reserved-item">
          {selectedData.contacts.length > 0 && <span className="nail-info__label">Contacts:</span>}
          {selectedData.contacts.map((item, index) => {
            return (
              <a
                key={index}
                href={item.value}
                className="nail-info__value"
                target="_blank"
                rel="noopener noreferrer"
                aria-label={item.value}>
                {item.label}
              </a>
            );
          })}
        </div>
      )}
      <div className="nail-info__reserved-item">
        <PuffLoader
          color={'#C6B689'}
          loading={loading}
          cssOverride={override}
          size={40}
          aria-label="Loading"
          data-testid="loader"
          speedMultiplier={0.8}
        />
      </div>
      <div className="nail-info__reserved-item nail-info__close">
        <Button title="Close" type="info" onClick={handleCloseNailInfo} />
      </div>
    </>
  );
};

const NailInfo = (props) => {
  return (
    <div
      className={
        props.isNailInfo ? 'nail-info justify-center closed' : 'nail-info justify-center opened'
      }>
      <div
        className={
          !props.selectedData.isSelected ? 'nail-info_block' : 'nail-info_block nail-info_bordered'
        }>
        <div className="nail-info__close-btn">
          <CloseIcon onClick={props.handleCloseNailInfo} />
        </div>
        {props.selectedData.isSelected &&
          props.selectedData.status == 'available' &&
          renderAvailableNailInfo(
            props.selectedData,
            props.price,
            props.priceLimit,
            props.handlePriceChange,
            props.isPriceError,
            props.handleClick,
            props.handleShowKeyboard,
            props.handleBlur
          )}
        {props.selectedData.isSelected &&
          props.selectedData.status == 'purchased' &&
          renderPurchasedNailInfo(props.selectedData, props.handleCloseNailInfo)}
      </div>
    </div>
  );
};

export default NailInfo;
