import React, { useEffect, useState } from 'react';

import './GoldenCristianoMarble.scss';

import API from '../../../../API/API';
import DomPurify from '../../../../components/sanitizeHtml/DomPurify';

import marbleMainImage from '../../../../assets/golden-cristiano/marble-main-image.png';
import marbleMaterialImage from '../../../../assets/golden-cristiano/marble-material-image.png';
import WavyText from '../../../../components/WavyText/WavyText';
import { motion } from 'framer-motion';
import useMotion from '../../../../hooks/useMotion';
import bottomVariant from '../../../../animations/bottomVariant';

const GoldenCristianoMarble = () => {
  const { controls, ref } = useMotion();
  const [info, setInfo] = useState({
    title: 'The marble',
    description:
      'Heritage porters are painted on white Carrara marble (Italy). Marble is a very durable stone, resistant to humidity, high and low temperatures, having a medium density and porous structure, it is perfectly amenable to processing.',
    text3:
      'Carrara marble is mined in the Apuan Alps in Carrara (Italy). It is considered one of the most valuable types of marble in the world.',
    image: marbleMainImage,
    sub_text: 'The canvas for the portrait is 1200mm*1500mm and weighs more than 350kg.',
    image2: marbleMaterialImage
  });

  useEffect(() => {
    API.nailMap
      .mainPageInfo()
      .then((res) => {
        setInfo(res.data.about_marble);
      })
      .catch((err) => {
        console.error('error getting marble data', err);
      });
  }, []);

  return (
    <div ref={ref} className="marble">
      <WavyText text={info?.title} textClassName={'text-center h3'} duration={0.3} />

      <div className="marble__content">
        <div className="marble__left-block">
          <div className={'text text_sm marble__text'}>
            <DomPurify dirtyText={info?.description} />
          </div>
          <div className="marble__detail">
            <div className={'text text_sm marble_mobile_left_margin'}>
              <DomPurify dirtyText={info?.sub_text} />
            </div>
            <div className={'text text_sm marble_mobile_left_margin'}>
              <DomPurify dirtyText={info?.text3} />
            </div>
          </div>
          <div className="marble__overflow"></div>
          <div className="marble__central-block">
            <img className="marble__image marble__image_tablet" src={info?.image2} alt="nails" />
          </div>
        </div>

        <motion.div
          animate={controls}
          initial="hidden"
          variants={bottomVariant}
          className="marble__right-block">
          <img className="marble__main-image" src={info?.image} alt="nails" />
          <img className="marble__image_mobile" src={info?.image2} alt="nails" />
        </motion.div>
      </div>
    </div>
  );
};

export default GoldenCristianoMarble;
