export const displayWidth = [
  { width: 280, portraitWidth: 214, portraitHeight: 306 }, // Mobile minimum
  { width: 320, portraitWidth: 214, portraitHeight: 306 },
  { width: 360, portraitWidth: 305, portraitHeight: 420 },
  { width: 375, portraitWidth: 280, portraitHeight: 390 },
  { width: 390, portraitWidth: 312, portraitHeight: 434 },
  { width: 393, portraitWidth: 312, portraitHeight: 434 },
  { width: 412, portraitWidth: 354, portraitHeight: 484 },
  { width: 414, portraitWidth: 334, portraitHeight: 464 },
  { width: 428, portraitWidth: 364, portraitHeight: 524 },
  { width: 430, portraitWidth: 364, portraitHeight: 524 },
  { width: 480, portraitWidth: 402, portraitHeight: 594 },
  { width: 540, portraitWidth: 278, portraitHeight: 398 }, // Mobile maximum

  { width: 1360, portraitWidth: 372, portraitHeight: 514 }, // 15-inch Macbook pro 2012
  { width: 1360, portraitWidth: 372, portraitHeight: 514 }, // 15-inch Macbook pro 2012
  { width: 1512, portraitWidth: 372, portraitHeight: 514 }, // 15-inch Macbook pro 2012
  { width: 2880, portraitWidth: 978, portraitHeight: 1378 }, // 15-inch Macbook pro 2012
  { width: 3024, portraitWidth: 1084, portraitHeight: 1524 }, // 14-inch Macbook pro 2021
  { width: 3072, portraitWidth: 1084, portraitHeight: 1524 }, // 16-inch Macbook pro 2021
  { width: 3456, portraitWidth: 1254, portraitHeight: 1764 }, // 16-inch Macbook pro 2019
  { width: 4096, portraitWidth: 1254, portraitHeight: 1764 }, // 21.5-inch iMac 2015
  { width: 4480, portraitWidth: 1326, portraitHeight: 1874 }, // 24-inch iMac 2021
  { width: 5120, portraitWidth: 1514, portraitHeight: 2120 }, // 27-inch iMac 2014
  { width: 6016, portraitWidth: 1912, portraitHeight: 2692 }, // Apple display pro
  
  { width: 768, portraitWidth: 492, portraitHeight: 680 }, // iPad mini
  { width: 810, portraitWidth: 560, portraitHeight: 774 }, // iPad air
  { width: 820, portraitWidth: 580, portraitHeight: 794 }, // iPad air
  { width: 834, portraitWidth: 630, portraitHeight: 884 }, // iPad air
  { width: 1024, portraitWidth: 360, portraitHeight: 506 }, // iPad landscape

  { width: 1280, portraitWidth: 348, portraitHeight: 520 }, // Super-eXtended Graphics Array (SXGA)
  { width: 1366, portraitWidth: 400, portraitHeight: 560 }, // High Definition (HD)
  { width: 1600, portraitWidth: 472, portraitHeight: 664 }, // High Definition Plus (HD+)
  { width: 1920, portraitWidth: 462, portraitHeight: 670 }, // Full High Definition (FHD)
  { width: 2048, portraitWidth: 592, portraitHeight: 832 }, // 2K
  { width: 2560, portraitWidth: 742, portraitHeight: 1040 }, // Quad High Definition (QHD)
  { width: 3440, portraitWidth: 822, portraitHeight: 1136 }, // Wide Quad High Definition (WQHD)
  { width: 3840, portraitWidth: 1192, portraitHeight: 1684 }, // 4K or Ultra High Definition (UHD)
];

export const paymentTexts = {
  successfulTitleText: 'Thank you',
  rejectedTitleText: 'Sorry',
  successfulDescText: 'Your payment was successful! Within one day, a certificate will be sent to your email. If you have not received your certificate within this time, you can go to the contact page for feedback with support',
  rejectedDescText: 'Unfortunately, the payment was not successful. You need to contact your bank to find out the reasons.',
  successfulStatus: 'successful',
  rejectedStatus: 'rejected'
}

export const paymentStatuses = [
  { code: '0', message: 'Your payment was successful! Within one day, a certificate will be sent to your email. If you have not received your certificate within this time, you can go to the contact page for feedback with support' },
  { code: '33', message: 'Invalid CVC2 code' },
  { code: '38', message: 'The limit of attempts to set a PIN has been reached' },
  { code: '51', message: 'There are not enough funds to pay' },
  { code: '54', message: 'Your card has expired' },
  { code: '61', message: 'The withdrawal amount exceeds the card limit' },
  { code: '75', message: 'Invalid PIN code. The allowed number of attempts to dial the PIN code has been exhausted' },
  { code: '76', message: 'Invalid PIN code. All attempts were exhausted' },
  { code: '18', message: 'Invalid CVC2 code' },
]