import React, { useState } from 'react';

import './Search.scss';

import {ReactComponent as SearchIcon} from '../../assets/search.svg';

const Search = ({ onChange, setZoomed }) => {
  const [showInput, setShowInput] = useState(false);

  const handleClick = () => {
    if (window.innerWidth < 1024) setZoomed(true);
    setShowInput(!showInput)
  }

  return (
    <div className='search'>
      <SearchIcon onClick={handleClick} />
      <input
        type='number'
        className={showInput ? 'search__input search__input_fade-in' : 'search__input search__input_fade-out'}
        placeholder='search nail by number'
        onChange={(e) => onChange(e)}
      />

    <input
        type='number'
        className={showInput ? 'search__input_mobile search__input_fade-in' : 'search__input_mobile search__input_fade-out'}
        placeholder='search nail by number'
        onChange={(e) => onChange(e)}
      />
    </div>
  )
}

export default Search;