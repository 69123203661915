import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countries: []
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState: initialState,
  reducers: {
    setCoutriesData(state, action) {
      state.countries = action.payload;
    },
  }
})

export const {setCoutriesData} = countriesSlice.actions;
export default countriesSlice.reducer;